import { LqdSubmenuIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { ObjectOf } from "../../common/types/ObjectOf";
import { Simulator } from "../types/Simulator";
import { SimulatorFunction } from "../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../types/SimulatorFunctionBlock";
import { SimulatorFunctionTemplate } from "../types/SimulatorFunctionTemplate";
import { SimulatorVariableTemplateType } from "../types/SimulatorVariableTypeTemplate";

export const formatFunctionsForTooltip = (
  block: SimulatorFunctionBlock,
  functionTemplates: Array<SimulatorFunctionTemplate>,
  simulator: Simulator
) => {
  return block.functions.map((func, index) => (
    <Box key={`${func.function_name}-${index}`} sx={{ ":first-of-type": { mt: 0 }, mt: 1.5 }}>
      <LqdTypography color="rgba(255, 255, 255, 1)" sx={{ mb: 0.5 }} textstyle="c1CaptionMedium">
        {functionTemplates.find((template) => template.function_name === func.function_name)?.function_label ||
          func.function_name}
      </LqdTypography>
      {Object.values(func.variables).map((varValue, index) => {
        if (func.type === "action" && typeof varValue === "number") {
          return (
            <LqdTypography
              color="rgba(255, 255, 255, 1)"
              key={`${index}-${varValue}`}
              sx={{ ml: 3 }}
              textstyle="c1Caption"
            >
              • Bloco: {JSON.stringify(simulator.blocks.find((block) => block.block_id === varValue)?.name || varValue)}
            </LqdTypography>
          );
        } else {
          return (
            <LqdTypography
              color="rgba(255, 255, 255, 1)"
              key={`${index}-${varValue}`}
              sx={{ ml: 3 }}
              textstyle="c1Caption"
            >
              • Variável: {simulator.variables.find((vari) => vari.name === varValue)?.label || varValue}
            </LqdTypography>
          );
        }
      })}
      {Object.values(func.result).map((result, index) => (
        <LqdTypography color="rgba(255, 255, 255, 1)" key={`${index}-${result}`} sx={{ ml: 3 }} textstyle="c1Caption">
          • Resultado: {simulator.variables.find((vari) => vari.name === result)?.label || result}
        </LqdTypography>
      ))}
    </Box>
  ));
};

const getBlockLabels = (blockFunction: SimulatorFunction | undefined, blocks: Array<SimulatorFunctionBlock>) => {
  if (blockFunction) {
    const blockVariablesFiltered = Object.entries(blockFunction.variables).filter(([key]) => key.startsWith("block_"));

    return blockVariablesFiltered
      .map(([, variable]) => blocks.find((block) => block.block_id === Number(variable))?.name)
      .filter((name) => name !== undefined) as Array<string>;
  }

  return [];
};

export const getBlocksContent = (
  newBlock: boolean = false,
  block: SimulatorFunctionBlock | undefined,
  blocks: Array<SimulatorFunctionBlock>
) => {
  let blockLabelsToRender: Array<string> = [];

  if (block) {
    const blockHasConditionalJump = block.functions.find((func) => func.function_name === "jump_conditional");
    const blockHasJump = block.functions.find((func) => func.function_name === "jump");

    blockLabelsToRender = [...getBlockLabels(blockHasConditionalJump, blocks), ...getBlockLabels(blockHasJump, blocks)];
  }

  return newBlock ? (
    <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
      Crie um bloco com funções
    </LqdTypography>
  ) : (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" spacing={1} useFlexGap>
      {blockLabelsToRender.map((blockLabel) => (
        <Box
          key={blockLabel}
          sx={{ backgroundColor: "rgba(177, 227, 255, 1)", borderRadius: "100px", display: "flex", gap: 0.25, p: 1 }}
        >
          <LqdSubmenuIcon size={14} />
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="buttonXS">
            {blockLabel}
          </LqdTypography>
        </Box>
      ))}
    </Stack>
  );
};

export const predictResultFunctionType = (
  operation: string,
  inputTypes: Array<SimulatorVariableTemplateType>
): SimulatorVariableTemplateType => {
  const rules: ObjectOf<(types: Array<SimulatorVariableTemplateType>) => SimulatorVariableTemplateType> = {
    divide: (types: Array<SimulatorVariableTemplateType>): SimulatorVariableTemplateType => {
      const [typeA, typeB] = types;
      if (typeA === "currency" && typeB === "currency") return "percentage";
      if (typeA === "integer" && typeB === "integer") return "percentage";
      if (typeA === "currency") return "currency";
      return "percentage";
    },
    multiply: (types: Array<SimulatorVariableTemplateType>): SimulatorVariableTemplateType => {
      const [typeA, typeB] = types;
      if (typeA === "currency" && typeB === "percentage") return "currency";
      if (typeA === "percentage" && typeB === "currency") return "currency";
      if (typeA === "currency" || typeB === "currency") return "currency";
      return typeA;
    },
    subtract: (types: Array<SimulatorVariableTemplateType>): SimulatorVariableTemplateType => {
      const [typeA, typeB] = types;
      if (typeA === "currency" || typeB === "currency") return "currency";
      else return "integer";
    },
    subtract_multiple: (types) => {
      return types.reduce((resultType, currentType) => {
        if (resultType === "currency" || currentType === "currency") return "currency";
        return "integer";
      }, "integer");
    },
    sum: (types: Array<SimulatorVariableTemplateType>): SimulatorVariableTemplateType => {
      const [typeA, typeB] = types;
      if (typeA === "currency" || typeB === "currency") return "currency";
      else return "integer";
    },
    sum_multiple: (types: Array<SimulatorVariableTemplateType>): SimulatorVariableTemplateType => {
      return types.reduce((resultType, currentType) => {
        if (resultType === "currency" || currentType === "currency") return "currency";
        return "integer";
      }, "integer");
    },
  };

  return rules[operation](inputTypes);
};

export const isSomeFunctionNotCompletelyFilled = (currentBlock: SimulatorFunctionBlock, simulator: Simulator) => {
  return currentBlock?.functions.some((funct) => {
    const functResult = funct.result;
    const functVariables = funct.variables;

    let emptyResultLabel = false;

    Object.values(functResult).forEach((result) => {
      const variable = simulator.variables.find((variable) => variable.name === result);

      switch (true) {
        case variable === undefined:
        case variable && !variable.label.length:
          emptyResultLabel = true;
          return;
        case variable && variable.label.length > 0:
          emptyResultLabel = false;
          return;
        default:
          emptyResultLabel = true;
          return;
      }
    });

    const someEmptyFunctResult = Object.values(functResult).some((value) => value?.toString().trim() === "");
    const someEmptyfunctVariables = Object.values(functVariables).some((value) => value?.toString().trim() === "");

    const isEmptyKeyValues = emptyResultLabel || someEmptyFunctResult || someEmptyfunctVariables;

    let isEmptyKeys = false;

    switch (funct.function_name) {
      case "jump":
      case "jump_conditional":
      case "price_installments":
        isEmptyKeys = !Object.keys(functVariables).length;
        break;
      case "sum_multiple":
      case "subtract_multiple":
        isEmptyKeys = !Object.keys(functResult).length || Object.keys(functVariables).length <= 1;
        break;
      case "amazing_calc":
        isEmptyKeys = Object.keys(functResult).length < 2 || Object.keys(functVariables).length < 3;
        break;
      default:
        isEmptyKeys = !Object.keys(functResult).length || !Object.keys(functVariables).length;

        break;
    }

    return isEmptyKeyValues || isEmptyKeys;
  });
};
