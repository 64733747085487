import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";

type CalculateNodePositionProps = {
  block: SimulatorFunctionBlock;
  index: number;
  simulator: Simulator;
  unusedBlocks: Array<number>;
};

const calculateTargetInfo = (simulator: Simulator): Record<number, number> => {
  const targetInfo: Record<number, number> = {};

  simulator.blocks.forEach((block) => {
    const jumpConditionalFunction = block.functions.find((func) => func.function_name === "jump_conditional");

    if (jumpConditionalFunction) {
      const blockTrueId = Number(jumpConditionalFunction.variables.block_true);
      const blockFalseId = Number(jumpConditionalFunction.variables.block_false);

      const calculateInfo = (blockId: number): number => {
        const targetBlock = simulator.blocks.find((b) => b.block_id === blockId);
        if (!targetBlock) return 0;

        const variablesCount = targetBlock.functions.reduce((acc, func) => acc + Object.keys(func.variables).length, 0);
        const functionsCount = targetBlock.functions.length;

        return variablesCount * 20 + functionsCount * 30;
      };

      targetInfo[blockTrueId] = calculateInfo(blockTrueId);
      targetInfo[blockFalseId] = calculateInfo(blockFalseId);
    }
  });

  return targetInfo;
};

export default function calculateNodePosition(props: CalculateNodePositionProps) {
  const { block, index, simulator, unusedBlocks } = props;
  const targetInfo = calculateTargetInfo(simulator);

  const targetsFalse = simulator.blocks
    .filter((block) => block.functions.some((fn) => fn.function_name === "jump_conditional"))
    .flatMap((b) => b.functions)
    .filter((fn) => fn.function_name === "jump_conditional")
    .map((fn) => Number(fn.variables.block_false as number));

  const targetsTrue = simulator.blocks
    .filter((block) => block.functions.some((fn) => fn.function_name === "jump_conditional"))
    .flatMap((block) => block.functions)
    .filter((fn) => fn.function_name === "jump_conditional")
    .map((fn) => Number(fn.variables.block_true));

  const isUnused = unusedBlocks.includes(block.block_id);
  const isTargetFalse = targetsFalse.includes(block.block_id);
  const isTargetTrue = targetsTrue.includes(block.block_id);

  let x = 500 + index * 500;
  let y = 5;

  if (index === 0) {
    x = 200;
  }

  if (isTargetFalse) {
    y = 60 + (targetInfo[block.block_id] || 0) * 2;
    x = 200 + index * 500;
  }

  if (isTargetTrue) {
    y = 60 - (targetInfo[block.block_id] || 0) * 2;
    x = 200 + (index + 1) * 500;
  }

  if (isUnused && unusedBlocks.length === 1) {
    y =
      160 +
      block.functions.length * 28 +
      block.functions.reduce((acc, func) => acc + Object.keys(func.variables).length, 0) * 20 * 2;
  }

  if (isUnused && unusedBlocks.length > 1) {
    y =
      160 +
      block.functions.length * 28 +
      block.functions.reduce((acc, func) => acc + Object.keys(func.variables).length, 0) * 20 * 2;

    x = 300 + index * 500;
  }

  return {
    data: { block, unusedBlocks },
    id: `${block.block_id}`,
    position: { x, y },
    type: "customNode",
  };
}
