import { LqdIconProps } from "../../types/IconProps";

export default function LqdInputVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 25 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M18.2455 6.1592C21.4713 9.38499 21.4713 14.615 18.2455 17.8408C15.0197 21.0666 9.78965 21.0666 6.56387 17.8408C3.33808 14.615 3.33808 9.38497 6.56387 6.1592C9.78966 2.93341 15.0197 2.93341 18.2455 6.1592Z"
        fill={color || "rgb(207, 211, 218)"}
        fill-rule="evenodd"
      />
    </svg>
  );
}
