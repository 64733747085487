import { simulatorTesterFunctionClick } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import calculateNodePosition from "@simulatorBuilder/utils/calculateNodePosition";
import createSimulatorTesterEdges from "@simulatorBuilder/utils/createSimulatorTesterEdges";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import ReactFlow, { Background, ControlButton, MiniMap, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import setUnusedBlocks from "../../../utils/setUnusedBlocks";
import CustomNode from "./CustomNode/SimulatorTesterCustomNode";

type SimulatorTesterFlowDiagramProps = {
  hasRedirectedNode: boolean;
  setHasRedirectedNode: Dispatch<SetStateAction<boolean>>;
  simulator: Simulator;
};

const EMPTY_BLOCKS_NODE = [
  {
    data: {
      block: {
        block_id: 0,
        functions: [],
        name: "Não há blocos",
      },
      unusedBlocks: [],
    },
    id: "0",
    position: { x: 100, y: 200 },
    type: "customNode",
  },
];

export default function SimulatorTesterFlowDiagram(props: SimulatorTesterFlowDiagramProps) {
  const { hasRedirectedNode, setHasRedirectedNode, simulator } = props;

  const dispatch = useAppDispatch();

  const { selectedFunction, testerResult } = useAppSelector((state) => state.simulatorBuilder);

  const { fitView, getNode, setViewport } = useReactFlow();

  // Todo: O ideal é já começar nesta posição e não ser redirecionado
  const onInit = () => setViewport({ x: 100, y: 200, zoom: 0.8 });

  const unusedBlocks = useMemo(() => setUnusedBlocks(simulator), [simulator]);
  const initialEdges = createSimulatorTesterEdges(simulator);
  const [edges] = useEdgesState(initialEdges);

  const initialNodes = useMemo(
    () => simulator.blocks.map((block, index) => calculateNodePosition({ block, index, simulator, unusedBlocks })),
    [simulator, unusedBlocks]
  );

  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    simulator.blocks.length === 0 ? EMPTY_BLOCKS_NODE : initialNodes
  );

  useEffect(() => {
    if (selectedFunction) {
      const targetNode = getNode(String(selectedFunction.blockId));
      if (targetNode) {
        fitView({ duration: 300, nodes: [targetNode] });
        setHasRedirectedNode(true);
      }
    }

    if (testerResult?.result.error && !hasRedirectedNode) {
      const targetNode = getNode(String(testerResult.result.steps[testerResult.result.steps.length - 1].block_id));
      if (targetNode) {
        fitView({ duration: 300, nodes: [targetNode] });
        setHasRedirectedNode(true);
      }
    }

    setTimeout(() => {
      dispatch(simulatorTesterFunctionClick(null));
    }, 301);
  }, [getNode, hasRedirectedNode, selectedFunction, setViewport, testerResult]);

  useEffect(() => {
    if (simulator.blocks.length === 0) {
      setNodes(EMPTY_BLOCKS_NODE);
    } else {
      const updatedNodes = simulator.blocks.map((block, index) =>
        calculateNodePosition({ block, index, simulator, unusedBlocks })
      );

      setNodes(updatedNodes);
    }
  }, [simulator.blocks, unusedBlocks]);

  return (
    <ReactFlow edges={edges} fitView nodes={nodes} nodeTypes={nodeTypes} onInit={onInit} onNodesChange={onNodesChange}>
      <MiniMap />
      <ControlButton />
      <Background color="rgba(207, 211, 218, 1)" gap={20} size={2} />
    </ReactFlow>
  );
}
