import { SimulatorVariable } from "@/analysis-v3/src/features/simulatorBuilder/types/SimulatorVariable";
import { Box, Input } from "@mui/material";
import IMask from "imask";
import { ChangeEvent, useEffect, useRef } from "react";
import { LqdTypography } from "..";

type LqdTextFieldBoxProps = {
  disabled?: boolean;
  label?: string;
  labelType: "label" | "name";
  mask?: string;
  onBlur?: (resultValue: string) => Array<SimulatorVariable>;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  value: string;
};

export default function LqdTextFieldBox(props: LqdTextFieldBoxProps) {
  const { disabled, label, mask, onBlur, onChange, placeholder, value } = props;

  const newMask = useRef(
    IMask.createMask({
      mask: mask || "",
    })
  );

  useEffect(() => {
    if (mask) {
      newMask.current = IMask.createMask({ mask: mask || "" });
    }

    if (value) {
      newMask.current.value = value;
    }
  }, [mask, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (mask) {
      const newValue = event.target.value;
      const lastCharIsNumber = !isNaN(Number(newValue.slice(-1)));
      newMask.current.resolve(lastCharIsNumber ? newValue : newValue.slice(0, -1));
      event.target.value = newMask.current.unmaskedValue;
    }
    onChange(event.target.value);
  };

  const handleBlur = (resultValue: string) => {
    if (!onBlur) return;
    onBlur(resultValue);
  };

  return (
    <Box>
      {label ? (
        <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
          {label}
        </LqdTypography>
      ) : null}
      <Input
        disabled={disabled}
        disableUnderline
        onBlur={(event) => handleBlur(event.target.value)}
        onChange={handleChange}
        placeholder={placeholder}
        sx={{
          "&.MuiInput-root:after": { borderBottom: "none" },
          "&.MuiInput-root:hover:before": { borderBottom: "none" },
          "::before": { borderBottom: "none" },
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(229, 231, 235, 1)",
          borderBottom: "1px solid rgba(229, 231, 235, 1)",
          borderRadius: 2,
          height: "48px",
          mt: 0.75,
          paddingLeft: 2,
          paddingRight: 1,
          width: "100%",
        }}
        value={newMask.current.value ? newMask.current.value : value}
      />
    </Box>
  );
}
