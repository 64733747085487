import { LqdButton } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { onChangingCurrentPanel } from "@superadmin/adminSlice";
import { useAppDispatch } from "../../../../store";
import { AdminPagePanels } from "../../types/AdminPagePanels";

export default function TenantConfigPanelButtons() {
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "60px 50px", justifyContent: "space-between", ml: 8 }}>
      <Box>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.SIMULATOR_PARAMETRIZATION))}
          sx={{ mr: 4, width: "380px" }}
        >
          Parametrização de Simuladores
        </LqdButton>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.PROJECT_CONCLUSION_DATE))}
          sx={{ width: "380px" }}
        >
          Cadastro de Data de Conclusão da Obra
        </LqdButton>
      </Box>
      <Box>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.ANALYSIS_DELETION))}
          sx={{ mr: 4, width: "380px" }}
        >
          Exclusão de Conexões
        </LqdButton>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.PRODUCT_DELETION))}
          sx={{ width: "380px" }}
        >
          Exclusão de Produtos
        </LqdButton>
      </Box>
      <Box>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.GROUP_DELETION))}
          sx={{ mr: 4, width: "380px" }}
        >
          Exclusão de Grupos
        </LqdButton>
        <LqdButton
          onClick={() => dispatch(onChangingCurrentPanel(AdminPagePanels.USER_DELETION))}
          sx={{ width: "380px" }}
        >
          Exclusão de Usuários
        </LqdButton>
      </Box>
    </Box>
  );
}
