import { LqdIconProps } from "../../types/IconProps";

export default function LqdResultVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 14 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 15 15" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.72124 2.59878C6.9718 2.22114 7.52632 2.22114 7.77687 2.59878L9.10616 4.60226C9.19033 4.72911 9.31737 4.82141 9.46402 4.86226L11.7802 5.50738C12.2168 5.62898 12.3882 6.15636 12.1064 6.51135L10.6118 8.39469C10.5171 8.51393 10.4686 8.66328 10.4751 8.81538L10.5773 11.2176C10.5965 11.6703 10.1479 11.9963 9.72326 11.838L7.47023 10.9985C7.32757 10.9454 7.17054 10.9454 7.02789 10.9985L4.77485 11.838C4.35018 11.9963 3.90156 11.6703 3.92083 11.2176L4.02303 8.81537C4.0295 8.66328 3.98097 8.51393 3.88634 8.39469L2.39169 6.51135C2.10996 6.15636 2.28131 5.62898 2.71789 5.50738L5.03409 4.86226C5.18075 4.82141 5.30778 4.72911 5.39195 4.60226L6.72124 2.59878Z"
        fill={color}
      />
    </svg>
  );
}
