import { LqdIconButton, LqdPlusTwoIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { SimulatorFormView } from "@simulatorBuilder/types/SimulatorFormView";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  duplicateSimulator,
  onChangingCurrentView,
  onChangingIsEditSimulatorTenant,
  simulatorBuilderModalOpened,
} from "../simulatorBuilderSlice";
import SimulatorForm from "./SimulatorForm";
import SimulatorRow from "./SimulatorRow";

/** Página de gerenciamento de simuladores, do painel de superadmin. */
export default function SimulatorBuilderPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { simulatorData, simulators } = useAppSelector((state) => state.simulatorBuilder);

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 8,
          px: 8,
        }}
      >
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h4Headline">
          Simuladores
        </LqdTypography>
        <LqdTooltip
          placement="left"
          titlebody="Crie variáveis de entrada, funções e um dashboard personalizado."
          titleheader="Configurar novo simulador"
        >
          <LqdIconButton buttonsize="large" onClick={() => dispatch(simulatorBuilderModalOpened(""))} round="true">
            <LqdPlusTwoIcon />
          </LqdIconButton>
        </LqdTooltip>
      </Box>
      <Grid
        columns={{ lg: 14, md: 20 }}
        container
        sx={{
          ".MuiGrid-item": { pl: 2.75 },
          alignItems: "center",
          backgroundColor: "rgba(248, 248, 249, 1)",
          borderBottom: "1px solid rgba(222, 225, 229, 1)",
          borderRadius: "8px 8px 0 0",
          height: "80px",
          mx: 8,
          width: "70vw",
        }}
      >
        <Grid item lg={4} md={7} sx={{ border: "none" }}>
          <LqdTypography textstyle="p2Paragraph">Simulador</LqdTypography>
        </Grid>
        <Grid item lg={3} md={3} sx={{ border: "none" }}>
          <LqdTypography textstyle="p2Paragraph">Empresa</LqdTypography>
        </Grid>
        <Grid item lg={3} md={3} sx={{ border: "none" }}>
          <LqdTypography textstyle="p2Paragraph">Criação</LqdTypography>
        </Grid>
        <Grid item lg={3} md={7} sx={{ border: "none" }}>
          <LqdTypography textstyle="p2Paragraph">Status</LqdTypography>
        </Grid>
      </Grid>
      <Box sx={{ height: "380px", mr: 8, overflow: "auto", pl: 8, width: "70vw" }}>
        <>
          {Object.values(simulators).map((simulator) => (
            <SimulatorRow
              key={simulator.simulator_id}
              onDuplicateClick={() => {
                navigate(`/config/admin/${simulator.simulator_id}`);
                dispatch(duplicateSimulator(true));
                dispatch(simulatorBuilderModalOpened(simulator.simulator_id!));
              }}
              onEditSimulatorClick={() => {
                navigate(`/config/admin/${simulator.simulator_id}`);
                dispatch(onChangingCurrentView(SimulatorFormView.NAME));
                dispatch(simulatorBuilderModalOpened(simulator.simulator_id!));
              }}
              onEditSimulatorStepResumeClick={() => {
                navigate(`/config/admin/${simulator.simulator_id}`);
                dispatch(onChangingCurrentView(SimulatorFormView.RESUME));
                dispatch(simulatorBuilderModalOpened(simulator.simulator_id!));
              }}
              onEditTenantClick={() => {
                navigate(`/config/admin/${simulator.simulator_id}`);
                dispatch(onChangingCurrentView(SimulatorFormView.TENANT));
                dispatch(onChangingIsEditSimulatorTenant(true));
                dispatch(simulatorBuilderModalOpened(simulator.simulator_id!));
              }}
              simulator={simulator}
            />
          ))}
        </>
      </Box>
      {simulatorData ? <SimulatorForm /> : null}
    </>
  );
}
