import { Column, Row, RowContent, SimulatorTesterResult, Table } from "@simulatorBuilder/types/simulationTester";
import { v4 as uuid } from "uuid";
import { Simulator, SimulatorRaw, SimulatorTestDBResponse } from "../types/Simulator";
import {
  SimulatorDBResultDashboardTable,
  SimulatorResultCard,
  SimulatorResultTable,
  SimulatorResultTableColumn,
  SimulatorResultTableData,
  SimulatorResultTableRow,
} from "../types/SimulatorDashboard";
import { SimulatorFunction } from "../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../types/SimulatorVariable";

const transformFunctionResultsToSnakeCase = (functions: Array<SimulatorFunction>) => {
  return functions.map((funct) => {
    const functResult = funct.result;

    const transformedResult = Object.keys(functResult).reduce(
      (acc, key) => {
        acc[key] = functResult[key];
        return acc;
      },
      {} as Record<string, string>
    );

    return { ...funct, result: transformedResult };
  });
};

export const simulatorVariablesToResultCard = (content: SimulatorVariable, id: number): SimulatorResultCard => {
  const resultCard = {
    index: id,
    label: content.label,
    name: content.name,
    type: content?.type || null,
    value: content.value,
  };

  return resultCard;
};

export const parseSimulator = (simulator: SimulatorRaw): Simulator => {
  const { tables } = simulator.dashboard;

  let columns: Array<SimulatorResultTableColumn> = [];
  let rows: Array<SimulatorResultTableRow> = [];
  const dashboardTables: Array<SimulatorResultTableData> = [];

  tables.forEach((table) => {
    table.column_names.forEach((tableColumn) => {
      const column = {
        columnId: uuid(),
        columnName: tableColumn.name,
      };
      columns.push(column);
    });
    //cria uma divisão de linhas, e adiciona as células por linha
    table.data.forEach((cell) => {
      const row = {
        rowContent: table.data
          .map((tableCell) => {
            if (tableCell.line === cell.line) {
              return { data: tableCell, id: "" };
            } else {
              return { data: null, id: "" };
            }
          })
          .filter((lineContent) => lineContent.data !== null),
        rowId: cell.line,
      };

      row.rowContent.forEach((tableCell, index) => {
        const column = columns[tableCell.data?.column || index];
        tableCell!["id"] = column?.columnId;
      });

      rows.push(row);
    });

    const uniqueRows = rows.filter((value, index, self) => self.map((x) => x.rowId).indexOf(value.rowId) === index);
    const uniqueColumns = columns.filter(
      (value, index, self) => self.map((x) => x.columnId).indexOf(value.columnId) === index
    );

    dashboardTables.push({ columns: uniqueColumns, rows: uniqueRows });
    columns = [];
    rows = [];
  });

  // Todo: Do not use this kind of verification.
  // On the api, get the default variables using the 'get_default_variables' function when loading a simulator.
  const defaultVariablesPossibleIds = [
    "6ea19747-dfb0-497b-8f12-32aa009c4a77",
    "7ba70953-3ff8-4c10-be92-45058f405051",
    "e632f74f-5a95-44fc-8b2d-8db7b6b080c2",
    "061876ec-26cc-41af-8550-e60df610f4c6",
    "9e8134bb-8afb-4262-9a18-2e3a59996ee5",
    "13c1ba77-d12c-47bb-9dc2-6289d1d079f8",
    "0e9a090d-92a9-49bb-8a4a-aa7114f2cb15",
    "ff97ec0b-865a-428c-af04-698a769c07e4",
    "3a6202ed-8813-4de0-977b-84c06e9efc97",
    "6a298677-65b2-438f-905e-c6058bb9fa7c",
  ];

  const updatedVariables = simulator.variables.map((variable) => ({
    ...variable,
    category: (() => {
      if (variable.id && /^[0-9]+_[0-9]+_[A-Za-z]$/.test(variable.id)) {
        return "result";
      } else if (variable.is_input) {
        return "input";
      } else if (variable.id && defaultVariablesPossibleIds.includes(variable.id)) {
        return "default";
      } else {
        return "constant";
      }
    })(),
  }));

  const parsedSimulator = {
    ...simulator,
    dashboard: {
      cards: simulator.dashboard.cards,
      highlight: simulator.dashboard.highlight,
      tables: dashboardTables,
    },
    variables: updatedVariables,
  };

  return parsedSimulator;
};

export const parseRawSimulator = (simulator: Simulator, blocks: Array<SimulatorFunctionBlock>): SimulatorRaw => {
  const dbTables: Array<SimulatorDBResultDashboardTable> = [];

  simulator.dashboard.tables.forEach((table) => {
    const column_names: Array<{
      index: number;
      name: string;
    }> = [];
    const data: Array<SimulatorResultTable> = [];

    table.columns.forEach((tableColumn, columnIndex) => {
      column_names.push({
        index: columnIndex,
        name: tableColumn.columnName!,
      });
    });

    table.rows.forEach((row) => {
      row.rowContent.forEach((tableCell, index) => {
        if (tableCell.data?.column !== undefined) {
          data.push({
            column: index,
            label: tableCell.data.label,
            line: tableCell.data.line,
            name: tableCell.data.name,
            type: tableCell.data.type,
            value: tableCell.data.value,
          });
        }
      });
    });

    dbTables.push({ column_names, data });
  });

  const simulatorBlocks = blocks.map((block) => {
    const blockFunctions = transformFunctionResultsToSnakeCase(block.functions);
    return { ...block, functions: blockFunctions };
  });

  const rawParsedSimulator = {
    ...simulator,
    blocks: simulatorBlocks,
    dashboard: { ...simulator.dashboard, tables: dbTables },
  };

  return rawParsedSimulator;
};

export const parseSimulatorTesterResult = (simulator: SimulatorTestDBResponse): SimulatorTesterResult => {
  const { tables } = simulator.dashboard;

  let columns: Array<Column> = [];
  let rows: Array<Row> = [];
  const dashboardTables: Array<Table> = [];

  tables.forEach((table) => {
    table.column_names.forEach((tableColumn) => {
      const column: Column = {
        columnId: uuid(),
        columnName: tableColumn.name,
      };
      columns.push(column);
    });

    table.data.forEach((cell) => {
      const row: Row = {
        rowContent: table.data
          .map((tableCell) => {
            if (tableCell.line === cell.line) {
              return {
                data: {
                  column: tableCell.column,
                  label: tableCell.label,
                  line: tableCell.line,
                  name: tableCell.name,
                  type: tableCell.type || "",
                  value: tableCell.value,
                },
                id: "",
              };
            } else {
              return { data: null, id: "" };
            }
          })
          .filter((lineContent) => lineContent.data !== null) as Array<RowContent>,
        rowId: cell.line,
      };

      row.rowContent.forEach((tableCell) => {
        const column = columns[tableCell.data?.column || 0];
        tableCell.id = column?.columnId || "";
      });

      rows.push(row);
    });

    const uniqueRows = rows.filter((value, index, self) => self.map((x) => x.rowId).indexOf(value.rowId) === index);
    const uniqueColumns = columns.filter(
      (value, index, self) => self.map((x) => x.columnId).indexOf(value.columnId) === index
    );

    dashboardTables.push({ columns: uniqueColumns, rows: uniqueRows });
    columns = [];
    rows = [];
  });

  const parsedSimulatorTesterResult: SimulatorTesterResult = {
    dashboard: {
      cards: simulator.dashboard.cards.map((card) => ({
        index: card.index,
        label: card.label,
        name: card.name,
        type: card.type || "",
        value: card.value,
      })),
      highlight: simulator.dashboard.highlight.map((highlight) => ({
        index: highlight.index,
        label: highlight.label,
        name: highlight.name,
        type: highlight.type || "",
        value: highlight.value,
      })),
      tables: dashboardTables,
    },
    name: simulator.name,
    results: simulator.results,
    steps: simulator.steps,
  };

  return parsedSimulatorTesterResult;
};
