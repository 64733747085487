import { pluggyAgreementRefuse } from "@analysis/api/PluggyAgreementRefuse";
import { env } from "@common/utils/apiUtils";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { PluggyConnect } from "react-pluggy-connect";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import LiquidLogo from "../../common/components/LiquidLogo";
import useWindowSize from "../../common/hooks/useWindowSize";
import { getPluggyToken } from "../api/GetPluggyToken";
import OpenCreditLoadingState from "../components/OpenCreditConfirmation/OpenCreditLoadingState";
import OpenFinanceAcceptedState from "../components/OpenFinanceConfirmation/OpenFinanceAcceptedState";
import OpenFinanceFinishedState from "../components/OpenFinanceConfirmation/OpenFinanceFinishedState";
import OpenFinanceRefusedState from "../components/OpenFinanceConfirmation/OpenFinanceRefusedState";
import OpenFinanceStartState from "../components/OpenFinanceConfirmation/OpenFinanceStartState";

export default function OpenFinancePage() {
  const { agreeDisagree, token } = useParams();
  const { width } = useWindowSize();

  const [currentView, setCurrentView] = useState<"authorized" | "cancelled" | "initial" | "loading" | "unauthorized">(
    "loading"
  );
  const [pluggyToken, setPluggyToken] = useState<string | null>(null);
  const [showWidget, setShowWidget] = useState(false);
  const [widgetKey, setWidgetKey] = useState(uuid());

  const pluggySuccess = useRef(false);
  const isDisagreeRoute = agreeDisagree === "disagree";

  useEffect(() => {
    const init = async () => {
      if (token) {
        const req = await getPluggyToken(token);
        setPluggyToken(req.data.access_token);

        if (isDisagreeRoute) {
          setCurrentView("unauthorized");
        } else {
          setCurrentView("initial");
        }
      }
    };

    init();
  }, []);

  const onCancelClick = async () => {
    if (!token) return;

    await pluggyAgreementRefuse(token);
    setCurrentView("cancelled");
  };

  const onStartClick = () => {
    setShowWidget(true);

    if (showWidget) {
      setWidgetKey(uuid());
    }
  };

  const onWidgetClose = () => {
    if (pluggySuccess.current) {
      setCurrentView("authorized");
    }
  };

  const renderContent = () => {
    switch (currentView) {
      case "loading":
        return <OpenCreditLoadingState />;
      case "initial":
        return <OpenFinanceStartState onStart={onStartClick} pluggyToken={pluggyToken} />;
      case "unauthorized":
        return <OpenFinanceRefusedState onCancel={onCancelClick} onStart={onStartClick} pluggyToken={pluggyToken} />;
      case "cancelled":
        return <OpenFinanceFinishedState />;
      case "authorized":
        return <OpenFinanceAcceptedState />;
    }
  };

  return (
    <>
      <Box
        sx={{
          backdropFilter: "blur(15px)",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(250, 250, 250, 0.7)",
          borderBottom: "0.5px solid rgba(127, 135, 152, 0.08)",
          height: width === "sm" || width === "xs" ? "63px" : "96px",
          position: "sticky",
          px: { sm: 5, xs: 3 },
          top: 0,
          zIndex: 1,
        }}
      >
        <LiquidLogo sx={{ pb: { sm: "20px", xs: "4px" }, pt: { sm: "28px", xs: "14px" } }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          height: { lg: "calc(100vh - 97px)", xs: "calc(100vh - 120px)" },
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: currentView === "loading" ? "column" : { lg: "row", xs: "column-reverse" },
            justifyContent: currentView === "loading" ? "center" : { lg: "space-around", xs: "flex-start" },
            margin: "auto",
            width: "80vw",
          }}
        >
          <Box
            sx={{ alignItems: "center", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center" }}
          >
            {renderContent()}
          </Box>
        </Box>
      </Box>
      {pluggyToken && showWidget ? (
        <PluggyConnect
          connectToken={pluggyToken}
          includeSandbox={env === "development"}
          key={widgetKey}
          onClose={onWidgetClose}
        />
      ) : null}
    </>
  );
}
