import { LqdEditIcon, LqdIconButton, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Grid } from "@mui/material";
import { onUpdateSimulator } from "@simulatorBuilder/simulatorBuilderSlice";
import { format } from "date-fns";
import { useAppDispatch } from "../../../store";
import { Simulator } from "../types/Simulator";

type SimulatorRowProps = {
  onDuplicateClick: () => void;
  onEditSimulatorClick: () => void;
  onEditSimulatorStepResumeClick: () => void;
  onEditTenantClick: () => void;
  simulator: Simulator;
};

/** Componente responsável por uma linha da listagem da página de gerenciamento de simuladores. */
export default function SimulatorRow(props: SimulatorRowProps) {
  const { onDuplicateClick, onEditSimulatorClick, onEditSimulatorStepResumeClick, onEditTenantClick, simulator } =
    props;
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const handleToogleActivateSimulator = async () => {
    const simulatorState = simulator!.is_active;
    const simulatorData = { ...simulator!, is_active: !simulatorState };

    try {
      const requestData = {
        blocks: simulator!.blocks,
        openModal: false,
        simulator: simulatorData,
        simulatorId: simulatorData.simulator_id!,
      };
      await dispatch(onUpdateSimulator(requestData)).unwrap();
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    }
  };

  return (
    <Grid
      className="lqd-tenant-row"
      columns={{ lg: 14, md: 20 }}
      container
      sx={{
        "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
        ".MuiGrid-item": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 3,
        },
        borderBottom: "1px solid rgba(65, 70, 103, 0.24)",
      }}
    >
      <Grid item lg={4} md={7}>
        <LqdTypography textstyle="p2Paragraph">{simulator.name}</LqdTypography>
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          onClick={onEditSimulatorClick}
          sx={{ "&:hover": { color: "rgba(65, 70, 103, 1)", cursor: "pointer" } }}
          textstyle="p2Paragraph"
        >
          Editar
        </LqdTypography>
      </Grid>
      <Grid item md={3}>
        <LqdTypography sx={{ display: "inline" }} textstyle="p2Paragraph">
          {simulator.tenant_name}
        </LqdTypography>
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          onClick={onEditTenantClick}
          sx={{ "&:hover": { color: "rgba(65, 70, 103, 1)", cursor: "pointer" } }}
          textstyle="p2Paragraph"
        >
          Editar
        </LqdTypography>
      </Grid>
      <Grid item lg={3} md={3}>
        <LqdTypography textstyle="p2Paragraph">{format(simulator.created_at * 1000, "dd MMM yyyy")}</LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
          {format(simulator.created_at * 1000, "HH:mm")}
        </LqdTypography>
      </Grid>
      <Grid item>
        <LqdToggle active={simulator.is_active} onChange={handleToogleActivateSimulator} renderText />
      </Grid>
      <Grid sx={{ alignItems: "center", display: "flex", pl: 12 }}>
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          onClick={onDuplicateClick}
          sx={{ "&:hover": { color: "rgba(65, 70, 103, 1)", cursor: "pointer" } }}
          textstyle="p2Paragraph"
        >
          Duplicar
        </LqdTypography>
      </Grid>
      <Grid item>
        <LqdIconButton onClick={onEditSimulatorStepResumeClick} type="ghostIcon">
          <LqdEditIcon />
        </LqdIconButton>
      </Grid>
    </Grid>
  );
}
