import { LqdFunctionIcon, LqdInfoIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { highlightText } from "@common/utils/highlightText";
import { Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { SimulatorTesterCustomNodeData, SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { SimulatorFunction } from "@simulatorBuilder/types/SimulatorFunction";
import { useEffect, useState } from "react";
import { Handle, Position, ReactFlowProvider } from "reactflow";
import { useAppSelector } from "../../../../../../store";
import EmptyNode from "./EmptyNode";
import SimulatorTesterCustomNodeFunctionsTemplate from "./SimulatorTesterCustomNodeFunctionsTemplate";

type SimulatorTesterCustomNodeProps = {
  data: SimulatorTesterCustomNodeData;
};

export default function SimulatorTesterCustomNode(props: SimulatorTesterCustomNodeProps) {
  const { data } = props;

  const { simulator } = useSimulatorBuilder();

  const { functionTemplates, simulatorTesterSearchData, testerResult } = useAppSelector(
    (state) => state.simulatorBuilder
  );

  const errorMessage = testerResult?.result?.error;
  const brokenBlock =
    errorMessage && testerResult.result.steps ? testerResult.result.steps[testerResult.result.steps.length - 1] : null;

  const isFunctionBroken = (func: SimulatorFunction) => {
    return brokenBlock?.block_id === data.block.block_id && brokenBlock?.function_index === func.index;
  };

  const [inputSearchData, setInputSearchData] = useState<SimulatorTesterSearchData>({
    blocks: [],
    functions: [],
    searchedText: "",
    variables: [],
  });

  useEffect(() => {
    setInputSearchData(simulatorTesterSearchData);
  }, [simulatorTesterSearchData]);

  const toolTipMsg = `
  Nenhum bloco está conectado ao Bloco "${data.block.name}", por isso suas funções não serão calculadas.
  `;

  const { searchedText } = inputSearchData;

  if (!simulator.blocks.length) {
    return <EmptyNode data={data} />;
  }

  return (
    <ReactFlowProvider>
      <LqdTooltip
        followCursor={true}
        hidetooltip={!data.unusedBlocks.includes(data.block.block_id) ? "true" : "false"}
        titlebody={toolTipMsg}
      >
        <Stack
          sx={{
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(207, 211, 218, 1)",
            borderRadius: "20px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            fontFamily: "Arial, sans-serif",
            minWidth: "287px",
            padding: "15px",
            paddingLeft: 2.5,
            width: "fit-content",
          }}
        >
          <Stack
            sx={{
              opacity: data.unusedBlocks.includes(data.block.block_id) ? 0.6 : 1,
            }}
          >
            <Stack sx={{ flexDirection: "row", marginBottom: "10px" }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "rgba(177, 227, 255, 1)",
                  borderRadius: "50%",
                  color: "rgba(13, 65, 122, 1)",
                  height: 38,
                  width: 38,
                }}
              >
                <LqdFunctionIcon size={18} />
              </Stack>

              <Stack sx={{ marginLeft: "10px" }}>
                <LqdTypography color="rgba(0, 0, 0, 1)" textstyle="h5Headline">
                  {highlightText(data.block.name, searchedText, "20px")}
                </LqdTypography>
                <LqdTypography color="rgba(102, 102, 102, 1)" textstyle="c1Caption">
                  {`#${data.block.functions[0].index}`}
                </LqdTypography>
              </Stack>
            </Stack>

            {data.block.functions.map((func, key) => (
              <Stack key={key}>
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <LqdTypography
                    color={isFunctionBroken(func) ? "rgba(246, 61, 94, 1)" : "rgba(127, 135, 152, 1)"}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {highlightText(
                      functionTemplates.find((template) => template.function_name === func.function_name)
                        ?.function_label || "",
                      searchedText,
                      "12px"
                    )}
                  </LqdTypography>
                  {isFunctionBroken(func) ? (
                    <LqdTooltip
                      followCursor={true}
                      hidetooltip={!isFunctionBroken(func) ? "true" : "false"}
                      titlebody={errorMessage}
                    >
                      <LqdInfoIcon color="rgba(246, 61, 94, 1)" />
                    </LqdTooltip>
                  ) : null}
                </Stack>
                <SimulatorTesterCustomNodeFunctionsTemplate
                  data={data}
                  func={func}
                  searchedText={searchedText}
                  simulator={simulator}
                  testerResult={testerResult}
                />
              </Stack>
            ))}

            <>
              <Handle position={Position.Left} style={{ top: "50%" }} type="target" />
              <Handle position={Position.Right} style={{ top: "50%" }} type="source" />
            </>
          </Stack>
        </Stack>
      </LqdTooltip>
    </ReactFlowProvider>
  );
}
