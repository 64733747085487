import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ObjectOf } from "../common/types/ObjectOf";
import {
  onLoadGroupsAdmin,
  onLoadProducts,
  onLoadProviders,
  onLoadSegments,
  onLoadTemplates,
  onLoadTenants,
} from "./adminSliceThunks";
import { AdminPagePanels } from "./types/AdminPagePanels";
import { GroupResponse } from "./types/GroupResponse";
import { ProductResponse } from "./types/ProductResponse";
import { ProviderResponse } from "./types/ProviderResponse";
import { SegmentResponse } from "./types/SegmentResponse";
import { TemplateResponse } from "./types/TemplateResponse";
import { TenantResponse } from "./types/TenantResponse";

interface AdminState {
  createTemplate: boolean;
  createTemplateModalStep: number;
  createTenant: boolean;
  createTenantModalStep: number;
  currentPanel: AdminPagePanels;
  currentSelectedTenant: TenantResponse;
  editTemplateModalStep: number;
  editTenantModalStep: number;
  groups: ObjectOf<GroupResponse>;
  products: ObjectOf<ProductResponse>;
  providers: ObjectOf<ProviderResponse>;
  segments: ObjectOf<SegmentResponse>;
  templates: ObjectOf<TemplateResponse>;
  templateToEdit: TemplateResponse | null;
  tenants: ObjectOf<TenantResponse>;
  tenantToEdit: TenantResponse | null;
}

const initialState: AdminState = {
  createTemplate: false,
  createTemplateModalStep: 1,
  createTenant: false,
  createTenantModalStep: 1,
  currentPanel: AdminPagePanels.ADMIN,
  currentSelectedTenant: {} as TenantResponse,
  editTemplateModalStep: 1,
  editTenantModalStep: 1,
  groups: {},
  products: {},
  providers: {},
  segments: {},
  templateToEdit: null,
  templates: {},
  tenantToEdit: null,
  tenants: {},
};

const adminSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadGroupsAdmin.fulfilled, (state, action) => {
        const groups = action.payload!;

        state.groups = {};

        groups.forEach((group) => {
          state.groups[group.code] = group;
        });
      })
      .addCase(onLoadProducts.fulfilled, (state, action) => {
        const products = action.payload!;

        state.products = {};

        products.forEach((product) => {
          state.products[product.productId] = product;
        });
      })
      .addCase(onLoadProviders.fulfilled, (state, action) => {
        const providers = action.payload!;

        state.providers = {};

        providers.forEach((provider) => {
          state.providers[provider.providerId] = provider;
        });
      })
      .addCase(onLoadSegments.fulfilled, (state, action) => {
        const segments = action.payload!;

        state.segments = {};

        segments.forEach((segment) => {
          state.segments[segment.segmentId] = segment;
        });
      })
      .addCase(onLoadTemplates.fulfilled, (state, action) => {
        const templates = action.payload!;

        state.templates = {};

        templates.forEach((template) => {
          state.templates[template.templateId] = template;
        });
      })
      .addCase(onLoadTenants.fulfilled, (state, action) => {
        const tenants = action.payload!;

        state.tenants = {};

        tenants.forEach((tenant) => {
          state.tenants[tenant.code] = tenant;
        });
      }),
  initialState,
  name: "admin",
  reducers: {
    createTemplateModalClosed(state) {
      state.createTemplate = false;
      state.createTemplateModalStep = 1;
    },
    createTemplateModalOpened(state) {
      state.createTemplate = true;
    },
    createTemplateModalStepGoTo(state, action: PayloadAction<1 | 2 | 3 | 4 | 5>) {
      state.createTemplateModalStep = action.payload;
    },
    createTemplateModalStepNext(state) {
      if (state.createTemplateModalStep < 5) {
        state.createTemplateModalStep += 1;
      }
    },
    createTemplateModalStepPrev(state) {
      if (state.createTemplateModalStep > 1) {
        state.createTemplateModalStep -= 1;
      }
    },
    createTenanModalClosed(state) {
      state.createTenant = false;
      state.createTenantModalStep = 1;
    },
    createTenantModalOpened(state) {
      state.createTenant = true;
    },
    createTenantModalStepGoTo(state, action: PayloadAction<1 | 2 | 3 | 4 | 5>) {
      state.createTenantModalStep = action.payload;
    },
    createTenantModalStepNext(state) {
      if (state.createTenantModalStep < 5) {
        state.createTenantModalStep += 1;
      }
    },
    createTenantModalStepPrev(state) {
      if (state.createTenantModalStep > 1) {
        state.createTenantModalStep -= 1;
      }
    },
    editTemplateModalClosed(state) {
      state.templateToEdit = null;
      state.editTemplateModalStep = 1;
    },
    editTemplateModalOpened(state, action: PayloadAction<{ step: 1 | 2 | 3 | 4; template: TemplateResponse }>) {
      const { step, template } = action.payload;
      state.templateToEdit = template;
      state.editTemplateModalStep = step;
    },
    editTemplateModalStepGoTo(state, action: PayloadAction<1 | 2 | 3 | 4 | 5>) {
      state.editTemplateModalStep = action.payload;
    },
    editTenantModalClosed(state) {
      state.tenantToEdit = null;
      state.editTenantModalStep = 1;
    },
    editTenantModalOpened(state, action: PayloadAction<{ step: number; tenant: TenantResponse }>) {
      const { step, tenant } = action.payload;
      state.tenantToEdit = tenant;
      state.editTenantModalStep = step;
    },
    editTenantModalStepGoTo(state, action: PayloadAction<1 | 2 | 3 | 4>) {
      state.editTenantModalStep = action.payload;
    },
    editTenantModalStepPrev(state) {
      if (state.editTenantModalStep > 1) {
        state.editTenantModalStep -= 1;
      }
    },
    onChangingCurrentPanel(state, action: PayloadAction<AdminPagePanels>) {
      state.currentPanel = action.payload;
    },
    onSelectTenant(state, action: PayloadAction<string>) {
      const tenantId = action.payload;

      state.currentSelectedTenant = state.tenants[tenantId];
    },
  },
});

export const {
  createTemplateModalClosed,
  createTemplateModalOpened,
  createTemplateModalStepGoTo,
  createTemplateModalStepNext,
  createTemplateModalStepPrev,
  createTenanModalClosed,
  createTenantModalOpened,
  createTenantModalStepGoTo,
  createTenantModalStepNext,
  createTenantModalStepPrev,
  editTemplateModalClosed,
  editTemplateModalOpened,
  editTemplateModalStepGoTo,
  editTenantModalClosed,
  editTenantModalOpened,
  editTenantModalStepGoTo,
  editTenantModalStepPrev,
  onChangingCurrentPanel,
  onSelectTenant,
} = adminSlice.actions;

export default adminSlice.reducer;
