import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorTesterEdges } from "@simulatorBuilder/types/SimulatorTesterEdges";

export default function createSimulatorTesterEdges(simulator: Simulator) {
  const edges: Array<SimulatorTesterEdges> = [];
  let nextBlockId: number | null = null;
  let conditionalTargets: Array<number> | null = null;
  const visitedBlocks: Array<number> = [];
  let booleanVariable: string | number | null = null;

  simulator.blocks.forEach((block, index) => {
    const currentBlockId = block.block_id;

    if (nextBlockId !== null && currentBlockId !== nextBlockId && !conditionalTargets?.includes(currentBlockId)) {
      return;
    }

    if (visitedBlocks.includes(currentBlockId)) {
      return;
    }

    visitedBlocks.push(currentBlockId);

    const jumpFunction = block.functions.find((func) => func.function_name === "jump");
    if (jumpFunction) {
      const targetBlockId = jumpFunction.variables.block_id;
      edges.push({
        animated: true,
        id: `e${currentBlockId}-${targetBlockId}`,
        source: `${currentBlockId}`,
        target: `${targetBlockId}`,
        type: "jump",
      });

      nextBlockId = Number(targetBlockId);
      conditionalTargets = null;
      return;
    }

    const jumpConditionalFunction = block.functions.find((func) => func.function_name === "jump_conditional");
    if (jumpConditionalFunction) {
      const blockTrue = jumpConditionalFunction.variables.block_true;
      const blockFalse = jumpConditionalFunction.variables.block_false;

      booleanVariable = jumpConditionalFunction.variables.a;

      edges.push({
        animated: true,
        id: `e${currentBlockId}-${blockTrue}`,
        source: `${currentBlockId}`,
        target: `${blockTrue}`,
        type: "jump_conditional",
      });
      edges.push({
        animated: true,
        id: `e${currentBlockId}-${blockFalse}`,
        source: `${currentBlockId}`,
        target: `${blockFalse}`,
        type: "jump_conditional",
      });

      nextBlockId = null;

      conditionalTargets = [Number(blockTrue), Number(blockFalse)];

      return;
    }

    if (index < simulator.blocks.length - 1) {
      const nextSequentialBlockId = simulator.blocks[index + 1].block_id;

      if (!conditionalTargets || !conditionalTargets?.includes(nextSequentialBlockId)) {
        edges.push({
          animated: true,
          id: `e${currentBlockId}-${nextSequentialBlockId}`,
          source: `${currentBlockId}`,
          target: `${nextSequentialBlockId}`,
          type: "default",
        });

        nextBlockId = nextSequentialBlockId;
      } else {
        nextBlockId = null;
      }

      if (conditionalTargets && conditionalTargets.every((target) => visitedBlocks.includes(target))) {
        if (booleanVariable !== null) {
          const booleanValue = simulator.variables.find((variable) => variable.name === booleanVariable)?.value;

          const targetBlockId = conditionalTargets[booleanValue === '"true"' ? 0 : 1];

          if (nextSequentialBlockId) {
            edges.push({
              animated: true,
              id: `e${targetBlockId}-${nextSequentialBlockId}`,
              source: `${targetBlockId}`,
              target: `${nextSequentialBlockId}`,
              type: "default",
            });
          }

          booleanVariable = null;
        }
        conditionalTargets = null;
      }
    }
  });

  return edges;
}
