import { put } from "@common/utils/apiUtils";
import { SimulatorTestDBResponse, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { AxiosResponse } from "axios";

export const updateSimulatorTesterResult = (body: {
  inputs?: Array<SimulatorVariable>;
  is_results_active?: boolean;
  result?: SimulatorTestDBResponse | null;
  simulator_id: string;
  simulator_name?: string;
}): Promise<AxiosResponse<SimulatorTesterResultDBResponse>> =>
  put<SimulatorTesterResultDBResponse>(`/simulation/builder/simulator/${body.simulator_id}/result`, body);
