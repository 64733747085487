import { LqdButtonTypes } from "@/liquid-components/src/components/Button/Button";
import { Dialog, DialogContent, Stack } from "@mui/material";
import SimulatorTesterDialogHeader from "@simulatorBuilder/components/SimulatorTesterV1/SimulatorTesterDialogHeader";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store";
import { dialogCalled } from "../commonSlice";
import FullscreenDialogHeader from "./FullscreenDialogHeader";

export type FullScreenDialogExtraButton = {
  disabled: boolean;
  onClick: () => void;
  title: string;
  type: "tab" | LqdButtonTypes;
};

export type FullscreenDialogHeaderType = "default" | "simulatorTesterDialogHeader";

type FullscreenDialogProps = {
  action?: "create" | "edit";
  children?: ReactNode;
  currentStep?: number;
  existingChanges?: boolean;
  extraButtons?: Array<FullScreenDialogExtraButton>;
  handleGoBack?: () => void;
  hashValue?: string;
  headerType?: FullscreenDialogHeaderType;
  hideHeader?: boolean;
  onAddInputsClick?: () => void;
  onClose: (saving: boolean) => void;
  onEditClick?: () => void;
  onTabChange?: (tab: string) => void;
  open: boolean;
  showEditButton?: boolean;
  showSkeleton?: boolean;
  simulator?: Simulator;
  tabValue?: string;
  title: string;
  useTesterResultFunction?: (value: boolean) => void;
};

export default function FullscreenDialog(props: FullscreenDialogProps) {
  const {
    action,
    children,
    currentStep,
    existingChanges,
    extraButtons,
    handleGoBack,
    hashValue,
    headerType = "default",
    hideHeader,
    onAddInputsClick,
    onClose,
    onEditClick,
    onTabChange,
    open,
    showEditButton,
    showSkeleton,
    simulator,
    tabValue,
    title,
    useTesterResultFunction,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const confirmCloseDialog = () => {
    dispatch(dialogCalled(null));
    onClose(false);
  };

  const handleOnClose = () => {
    if (action === "edit" || action === "create") {
      if (existingChanges) {
        dispatch(
          dialogCalled({
            actions: [{ title: "Voltar" }, { onClick: confirmCloseDialog, title: "Sair" }],
            body: t(`Há uma
              ${action === "edit" ? "edição" : "criação"}
              em andamento, sair agora cancelará as alterações não salvas. Deseja continuar?`),
            title: t("Atenção"),
            type: "default",
          })
        );
      } else {
        onClose(false);
      }
    } else {
      onClose(false);
    }
  };

  const showBackButton = action === "create" && (currentStep || 1) > 1;

  const getCommonHeaderProps = () => ({
    extraButtons,
    goBack: handleGoBack,
    hashValue,
    onAddInputsClick,
    onClose: handleOnClose,
    onEditClick,
    onTabChange,
    showBackButton,
    showEditButton,
    showSkeleton,
    tabValue,
    title,
  });

  const headers = {
    default: <FullscreenDialogHeader {...getCommonHeaderProps()} />,
    simulatorTesterDialogHeader: (
      <SimulatorTesterDialogHeader
        simulator={simulator}
        testerResultFunction={useTesterResultFunction}
        {...getCommonHeaderProps()}
      />
    ),
  };

  return (
    <Dialog fullScreen onClose={() => onClose(false)} open={open} sx={{ display: "flex", flexDirection: "column" }}>
      <DialogContent sx={{ flex: 1, p: 0 }}>
        <>
          {!hideHeader ? headers[headerType] || headers["default"] : null}
          <Stack sx={{ height: "calc(100% - 96px)" }}>{children}</Stack>
        </>
      </DialogContent>
    </Dialog>
  );
}
