import { Box } from "@mui/material";
import { DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Connection, Edge, EdgeChange, MarkerType, NodeChange } from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";
import ReactFlow, { Background, Controls, Node, ReactFlowInstance } from "react-flow-renderer/nocss";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { toastCalled } from "../../../../../common/commonSlice";
import { ToastNotification } from "../../../../../common/types/ToastNotification";
import getKey from "../../../../../common/utils/getKey";
import CustomEdge from "../../../../../productBuilder/components/CustomEdge/CustomEdge";
import { MarkerDefinition } from "../../../../../productBuilder/components/CustomEdge/MarkerDefinition";
import {
  connectionAdded,
  edgesChanged,
  edgesUpdated,
  initialRulesChanged,
  nodesAdded,
  nodesChanged,
  onLoadProductBuilderForTemplate,
  proponentsChanged,
  resetProductBuilderState,
} from "../../../../../productBuilder/productBuilderSlice";
import { ActionNodeData } from "../../../../../productBuilder/types/NodeData";
import Proponent from "../../../../../productBuilder/types/Proponent";
import RawNode from "../../../../../productBuilder/types/RawNode";
import getRules from "../../../../../productBuilder/utils/getRules";
import { validateEdges } from "../../../../../productBuilder/utils/validateEdges";
import TemplateNode from "./TemplateNode";

export default function TemplateFormStep4Flow() {
  const dispatch = useAppDispatch();

  const { badNodes, edges, nodeCategories, nodes, proponents } = useAppSelector((state) => state.productBuilder);
  const { templateToEdit } = useAppSelector((state) => state.admin);

  // Faz o loading dos node categories no início
  useEffect(() => {
    const init = async () => {
      dispatch(resetProductBuilderState());
      await dispatch(onLoadProductBuilderForTemplate());
    };
    init();
    return () => {
      dispatch(resetProductBuilderState());
    };
  }, []);

  // Insere as rules do template no productBuilderSlice, após o carregamento dos node categories
  useEffect(() => {
    if (nodeCategories.length) {
      const baseRawNodes: Array<RawNode> = templateToEdit?.rules || [];
      const baseEdges: Array<Edge> = templateToEdit?.edges || [];
      const rules = getRules(baseRawNodes, nodeCategories);
      const baseProponents: Array<Proponent> = templateToEdit ? rules.proponents : [];
      dispatch(nodesAdded(rules.nodes));
      dispatch(proponentsChanged(baseProponents));
      dispatch(edgesUpdated(baseEdges));
      dispatch(initialRulesChanged(baseRawNodes));
    }
  }, [nodeCategories]);

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);

  const nodeTypes = useMemo(() => ({ liquidNode: TemplateNode }), []);
  const edgeTypes = useMemo(() => ({ customedge: CustomEdge }), []);

  // Faz a validação das edges quando os nodes forem modificados (por exemplo, limpa as edges inválidas quando um nó é deletado)
  useEffect(() => {
    const validEdges = validateEdges(edges, nodes, proponents);
    dispatch(edgesUpdated(validEdges));
  }, [nodes]);

  const onConnect = (connection: Connection) => {
    dispatch(connectionAdded(connection));
  };

  const onAddNodes = useCallback((nodes: Array<Node>) => {
    dispatch(nodesAdded(nodes));
  }, []);

  const onNodesChange = useCallback((changes: Array<NodeChange>) => {
    if (event && (event as KeyboardEvent).key === "Backspace") {
      return;
    }

    dispatch(nodesChanged(changes));
  }, []);

  const onEdgesChange = useCallback((changes: Array<EdgeChange>) => {
    if (event && (event as KeyboardEvent).key === "Backspace") {
      return;
    }

    dispatch(edgesChanged(changes));
  }, []);

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      if (reactFlowBounds && reactFlowInstance) {
        const nodeDataJSON = event.dataTransfer.getData("application/reactflow");
        const nodeData = JSON.parse(nodeDataJSON);

        const desiredNode = nodeCategories
          .flatMap((nodeCategory) => nodeCategory.nodes)
          .find((node) => node.title === nodeData.title) as ActionNodeData;

        if (desiredNode) {
          nodeData.custom_data = desiredNode.custom_data;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left - 100,
          y: event.clientY - reactFlowBounds.top - 32,
        });

        const newNode = {
          data: nodeData,
          id: "node" + getKey(),
          position,
          type: "liquidNode",
        };
        onAddNodes([newNode]);
      }
    },
    [reactFlowInstance, nodeCategories]
  );

  useEffect(() => {
    if (badNodes.length) {
      const nodesToCenter = nodes.filter((node) => node.id === badNodes.find((badNodeId) => badNodeId === node.id));
      const NODE_WIDTH = 320;
      const NODE_AVG_HEIGHT = 200;
      const xPos = nodesToCenter.reduce((acc, node) => acc + node.position.x, 0) / nodesToCenter.length;
      const yPos = nodesToCenter.reduce((acc, node) => acc + node.position.y, 0) / nodesToCenter.length;
      reactFlowInstance?.setCenter(xPos + NODE_WIDTH, yPos + NODE_AVG_HEIGHT, { zoom: 0.7 });

      const notification: ToastNotification = {
        message: "Essa ligação não é permitida! Conecte somente componentes do mesmo tipo (PF com PF e PJ com PJ)",
        type: "warning",
      };

      dispatch(toastCalled(notification));
    }
  }, [badNodes]);

  // useEffect(() => {
  // TODO: aguardando implementação do badNodes (Cainã) no novo fluxo do Template para remover esse useEffect.
  // console.log("badNodes: ", badNodes);
  // }, [badNodes, edges]);

  return (
    <Box onDragOver={onDragOver} onDrop={onDrop} ref={reactFlowWrapper} sx={{ height: "100%" }}>
      <ReactFlow
        defaultEdgeOptions={{
          markerEnd: { height: 24, type: MarkerType.ArrowClosed, width: 24 },
          style: { strokeWidth: "3px" },
          type: "customedge",
        }}
        defaultPosition={[50, 50]}
        defaultZoom={0.8}
        edges={edges}
        edgeTypes={edgeTypes}
        maxZoom={5}
        minZoom={0.1}
        nodes={nodes}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        onInit={setReactFlowInstance}
        onNodesChange={onNodesChange}
      >
        <MarkerDefinition color="rgba(255, 0, 0, 1)" id="edge-marker-F63D5E" />
        <MarkerDefinition color="rgba(255, 255, 0, 1)" id="edge-marker-F6D83D" />
        <MarkerDefinition color="rgba(0, 128, 0, 1)" id="edge-marker-29B0A0" />
        <MarkerDefinition color="rgba(0, 0, 255, 1)" id="edge-marker-B3A9FF" />
        <Controls />
        <Background color="rgba(190, 198, 212, 1)" gap={16} size={1.5} />
      </ReactFlow>
    </Box>
  );
}
