import { LqdTypography } from "@/liquid-components/src";
import LqdConstantVariableIcon from "@/liquid-components/src/icons/ConstantVariable/ConstantVariable";
import LqdInputVariableIcon from "@/liquid-components/src/icons/InputVariable/InputVariable";
import LqdResultVariableIcon from "@/liquid-components/src/icons/ResultVariable/ResultVariable";
import { highlightText } from "@common/utils/highlightText";
import { truncateString } from "@common/utils/truncateString";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorTesterModalTab } from "@simulatorBuilder/types/SimulatorTesterModalTab";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../../store";

type SimulatorTesterLeftSideBarProps = {
  inputSearchData: SimulatorTesterSearchData;
  selectedTab: SimulatorTesterModalTab;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showSidebar: boolean;
  simulator: Simulator;
};

export default function SimulatorTesterLeftSideBar(props: SimulatorTesterLeftSideBarProps) {
  const { inputSearchData, selectedTab, setShowSidebar, showSidebar, simulator } = props;

  const { variableTypes } = useAppSelector((state) => state.simulatorBuilder);

  const variablesCategories = [
    ["constant", "Variáveis Constantes"],
    ["input", "Variáveis de entrada"],
    ["result", "Variáveis de Resultado"],
  ];

  const emptyVariablesBox = (
    <Box
      sx={{
        alignItems: "center",
        background: "rgba(249, 249, 250, 1)",
        borderRadius: 1.5,
        display: "flex",
        justifyContent: "center",
        mb: 2,
        mt: 1.5,
        padding: 1.5,
        textAlign: "center",
      }}
    >
      <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="l2Label">
        Ops. Você ainda não criou variáveis desse tipo.
      </LqdTypography>
    </Box>
  );

  const variablesBox = (variable: SimulatorVariable) => {
    return (
      <Box>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              background: "rgba(236, 238, 240, 1)",
              borderRadius: 5,
              mb: 0.5,
              p: "4px 8px",
              width: "fit-content",
            }}
          >
            <LqdTypography
              sx={{
                color: "rgba(127, 138, 155, 1)",
                fontSize: "10px",
                fontWeight: "400",
              }}
            >
              {truncateString(variable.name, 25)}
            </LqdTypography>
          </Stack>
          {variable.is_input ? (
            <Stack
              sx={{
                background: "rgba(236, 238, 240, 1)",
                borderRadius: 5,
                mb: 0.5,
                p: "4px 8px",
                width: "fit-content",
              }}
            >
              <LqdTypography color="rgba(127, 138, 155, 1)" sx={{ fontSize: "10px", fontWeight: "400px" }}>
                {variable.order_index}
              </LqdTypography>
            </Stack>
          ) : null}
        </Stack>
        <LqdTypography textstyle="l1Label">
          {inputSearchData && inputSearchData.searchedText
            ? highlightText(truncateString(variable.label, 25), inputSearchData.searchedText)
            : truncateString(variable.label, 25)}
        </LqdTypography>
        <Stack flexDirection="row" justifyContent="space-between">
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="l2Label">
            {variableTypes.find((type) => type.value === variable.type)?.label}:
          </LqdTypography>
          {variable.category === "constant" ? (
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="l2Label">
              {variable.value}
            </LqdTypography>
          ) : null}
        </Stack>
      </Box>
    );
  };

  const renderIcon = (variableType: string) => {
    switch (variableType) {
      case "Variáveis de entrada":
        return <LqdInputVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
      case "Variáveis Constantes":
        return <LqdConstantVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
      case "Variáveis de Resultado":
        return <LqdResultVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
    }
  };

  function setHighlightVariables(variableCategory: string) {
    switch (true) {
      case !inputSearchData.searchedText:
        return simulator.variables
          .filter((vari) => vari.category === variableCategory)
          .map((variable: SimulatorVariable, i: number) => (
            <Stack
              key={i}
              sx={{
                background: "rgba(249, 249, 250, 1)",
                borderRadius: 1.5,
                mt: 1.5,
                p: 1.5,
              }}
            >
              {variablesBox(variable)}
            </Stack>
          ));
      case !!inputSearchData.searchedText && !!inputSearchData.variables.length:
        return inputSearchData.variables
          .filter((vari) => vari.category === variableCategory)
          .map((variable: SimulatorVariable, i: number) => (
            <Stack
              key={i}
              sx={{
                background: "rgba(249, 249, 250, 1)",
                borderRadius: 1.5,
                mt: 1.5,
                p: 1.5,
              }}
            >
              {variablesBox(variable)}
            </Stack>
          ));
      default:
        return emptyVariablesBox;
    }
  }

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      {showSidebar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            maxHeight: "100%",
            overflowY: "auto",
            padding: "40px 20px",
            position: "relative",
            width: "259px",
          }}
        >
          {variablesCategories.map(([variableCategory, variableCategoryTitle], i) => (
            <Stack key={i} sx={{ display: "flex", flowDirection: "row" }}>
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{ alignItems: "center", display: "flex", gap: 1, marginBottom: 1 }}
                textstyle="l1Label"
              >
                {renderIcon(variableCategoryTitle)}
                {variableCategoryTitle}
              </LqdTypography>
              <Divider sx={{ color: "rgba(191, 196, 205, 0.24)" }} />
              <Stack>{simulator.variables.length ? setHighlightVariables(variableCategory) : emptyVariablesBox}</Stack>
            </Stack>
          ))}
        </Box>
      ) : null}
      <Box
        onClick={() => (selectedTab === SimulatorTesterModalTab.FLOW ? setShowSidebar(!showSidebar) : null)}
        sx={{
          "&:hover": { background: "rgb(233, 233, 233)" },
          alignItems: "center",
          backgroundColor: "rgba(236, 238, 240, 1)",
          cursor: "pointer",
          display: "flex",
          height: "100%",
          width: 16,
        }}
      >
        {showSidebar ? (
          <ArrowLeft sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        ) : (
          <ArrowRight sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        )}
      </Box>
    </Box>
  );
}
