import { LqdIconButton, LqdPlusTwoIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import {
  createTenanModalClosed,
  createTenantModalOpened,
  createTenantModalStepPrev,
  editTenantModalClosed,
  editTenantModalStepPrev,
} from "../../adminSlice";
import { onLoadTenants, onUpdateTenant } from "../../adminSliceThunks";
import { TenantResponse } from "../../types/TenantResponse";
import CreateTenantForm from "./CreateTenantForm";
import EditTenantForm from "./EditTenantForm";
import TenantRow from "./TenantRow";

export default function TenantConfig() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { createTenant, createTenantModalStep, editTenantModalStep, providers, tenants, tenantToEdit } = useAppSelector(
    (state) => state.admin
  );

  const initialCheckedProviders = Object.values(providers)
    .filter((provider) => provider.global)
    .map((provider) => provider.providerId);

  const [checkedProviders, setCheckedProviders] = useState<Array<string>>(initialCheckedProviders);
  const [checkedSegments, setCheckedSegments] = useState<Array<string>>([]);
  const [tenantName, setTenantName] = useState("");

  const checkedProvidersChanged =
    JSON.stringify([...(tenantToEdit?.providers || [])].sort()) !== JSON.stringify([...checkedProviders].sort());
  const checkedSegmentsChanged =
    JSON.stringify([...(tenantToEdit?.segments || [])].sort()) !== JSON.stringify([...checkedSegments].sort());
  const tenantNameChanged = tenantName !== tenantToEdit?.contact.name;

  const anythingChanged = tenantNameChanged || checkedProvidersChanged || checkedSegmentsChanged;

  // Seta os valores do form (nome, segmentos e providers) toda vez que o tenant editado mudar
  useEffect(() => {
    if (tenantToEdit) {
      setTenantName(tenantToEdit.contact.name);
      setCheckedProviders(tenantToEdit.providers);
      setCheckedSegments(tenantToEdit.segments);
    } else {
      setTenantName("");
      setCheckedProviders([]);
      setCheckedSegments([]);
    }
  }, [tenantToEdit, createTenant]);

  const handleCloseCreateClick = () => {
    dispatch(createTenanModalClosed());
  };

  const handleCloseEditClick = () => {
    dispatch(editTenantModalClosed());
  };

  const handleTenantStatusChange = (tenant: TenantResponse) => {
    if (!tenant.isBlocked) {
      dispatch(
        dialogCalled({
          actions: [{ title: "Voltar" }, { onClick: () => confirmTenantStatusChange(tenant), title: "Desativar" }],
          body: "Ao desativá-la, a empresa não poderá criar ou editar conexões. Deseja prosseguir?",
          title: "Desativar Empresa",
          type: "default",
        })
      );
    } else {
      confirmTenantStatusChange(tenant);
    }
  };

  const confirmTenantStatusChange = async (tenant: TenantResponse) => {
    dispatch(dialogLoaded(true));

    const isBlocked = tenant.isBlocked;

    try {
      const requestBody = {
        contact: tenant.contact,
        is_blocked: !isBlocked,
        providers: tenant.providers,
        segments: tenant.segments,
      };

      await dispatch(onUpdateTenant({ tenantCode: tenant.code, tenantInfo: requestBody }));
      await dispatch(onLoadTenants());

      const notification: ToastNotification = {
        message: `A empresa foi ${!isBlocked ? "desativada" : "ativada"} com sucesso.`,
        type: "success",
      };

      dispatch(toastCalled(notification));
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dialogLoaded(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 8,
          px: 12,
        }}
      >
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h4Headline">
          Empresas
        </LqdTypography>
        <LqdTooltip
          placement="left"
          titlebody="Crie uma empresa, possibilitando que ela cadastre usuários e utilize os serviços da Liquid."
          titleheader="Adicionar uma empresa"
        >
          <LqdIconButton onClick={() => dispatch(createTenantModalOpened())} round="true">
            <LqdPlusTwoIcon />
          </LqdIconButton>
        </LqdTooltip>
      </Box>
      <>
        <Grid
          columns={{ lg: 14, md: 20 }}
          container
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(248, 248, 249, 1)",
            borderBottom: "1px solid rgba(222, 225, 229, 1)",
            borderRadius: "8px 8px 0 0",
            height: "80px",
            mx: 8,
            width: "70vw",
          }}
        >
          <Grid item lg={5.5} md={7} sx={{ border: "none", pl: 3 }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              Empresa
            </LqdTypography>
          </Grid>
          <Grid item lg={2} md={3} sx={{ border: "none" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              Segmentos
            </LqdTypography>
          </Grid>
          <Grid item lg={2} md={3} sx={{ border: "none" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              Providers
            </LqdTypography>
          </Grid>
          <Grid item lg={2} md={4} sx={{ border: "none" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              Criação
            </LqdTypography>
          </Grid>
          <Grid item lg={2} md={3} sx={{ border: "none" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              Status
            </LqdTypography>
          </Grid>
        </Grid>
        <Box sx={{ height: "380px", mr: 8, overflow: "auto", pl: 8, width: "70vw" }}>
          {Object.values(tenants).map((tenant) => (
            <TenantRow handleTenantStatusChange={handleTenantStatusChange} key={tenant.code} tenant={tenant} />
          ))}
        </Box>
      </>
      <FullscreenDialog
        action="create"
        currentStep={createTenantModalStep}
        handleGoBack={() => dispatch(createTenantModalStepPrev())}
        onClose={handleCloseCreateClick}
        open={createTenant}
        title="Adicionar Empresa"
      >
        <CreateTenantForm
          checkedProviders={checkedProviders}
          checkedSegments={checkedSegments}
          name={tenantName}
          onClose={handleCloseCreateClick}
          setCheckedProviders={setCheckedProviders}
          setCheckedSegments={setCheckedSegments}
          setName={setTenantName}
        />
      </FullscreenDialog>
      <FullscreenDialog
        action="edit"
        currentStep={editTenantModalStep}
        existingChanges={anythingChanged}
        handleGoBack={() => dispatch(editTenantModalStepPrev())}
        onClose={handleCloseEditClick}
        open={Boolean(tenantToEdit)}
        title="Edição de Empresa"
      >
        <EditTenantForm
          checkedProviders={checkedProviders}
          checkedSegments={checkedSegments}
          onCloseDialog={handleCloseEditClick}
          setCheckedProviders={setCheckedProviders}
          setCheckedSegments={setCheckedSegments}
          setTenantName={setTenantName}
          tenant={tenantToEdit!}
        />
      </FullscreenDialog>
    </>
  );
}
