import { SmallDialogInputs } from "@common/components/SmallDialog/SmallDialog";

// Formata os valores de input de moeda para serem exibidos no formato de moeda
export const formatCurrencyInput = (value: string) => {
  const numericValue = parseInt(value.replace(/\D/g, "") || "0", 10);
  return (numericValue / 100).toLocaleString("pt-BR", { currency: "BRL", style: "currency" });
};

// Formata os valores de input de moeda para serem exibidos no formato de moeda
export const formatCurrencyDisplay = (value: string) => {
  const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
  return numericValue.toLocaleString("pt-BR", { currency: "BRL", style: "currency" });
};

// Formata os valores de input de data para serem exibidos no formato de data
export const formatDateInput = (value: string) => {
  const numericValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  let formattedValue = numericValue;

  if (numericValue.length >= 3 && numericValue.length <= 4) {
    formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`;
  }

  if (numericValue.length >= 5) {
    formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4, 8)}`;
  }

  return formattedValue;
};

// Formata os valores de input de porcentagem para serem exibidos no formato de porcentagem
export const formatPercentageInput = (value: string) => {
  const numericValue = parseInt(value.replace(/\D/g, "") || "0", 10);
  return `${numericValue}%`;
};

export const getUnformattedValues = (inputValues: Array<SmallDialogInputs>) =>
  inputValues.map((input) => {
    if (input.type === "currency") {
      return { ...input, value: (parseInt(input.value.replace(/[^\d]/g, ""), 10) / 100).toString() };
    }
    if (input.type === "percentage") {
      return { ...input, value: (parseInt(input.value.replace(/[^\d]/g, ""), 10) / 100).toString() };
    }
    return input;
  });

export const processInputValue = (type: string, value: string) => {
  switch (type) {
    case "currency":
      return formatCurrencyInput(value);
    case "date":
      return formatDateInput(value);
    case "integer":
      return value.replace(/\D/g, "");
    case "percentage":
      return formatPercentageInput(value);
    default:
      return value;
  }
};
