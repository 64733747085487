import { LqdTypography } from "@/liquid-components/src";
import BuilderSimulatorHighlightCards from
  "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/BuilderSimulatorHighlightCards";
import BuilderSimulatorInsightCards from
  "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/BuilderSimulatorInsightCards";
import BuilderSimulatorTables from
  "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/BuilderSimulatorTables";
import { Box, Stack } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { mapToBuilderSimulation } from "@simulatorBuilder/utils/mapToBuilderSimulation";

type SimulatorTesterResultProps = {
  inputSearchData: SimulatorTesterSearchData;
  result: SimulatorTesterResultDBResponse | null;
};

export default function SimulatorTesterResult(props: SimulatorTesterResultProps) {
  const { inputSearchData, result } = props;

  const builderSimulation = result ? mapToBuilderSimulation(result) : null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pb: "80px",
        width: "922px",
      }}>
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Cards
        </LqdTypography>
        <Box margin="28px">
          <BuilderSimulatorInsightCards inputSearchData={inputSearchData} simulation={builderSimulation!} />
        </Box>
      </Stack>
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Destaques
        </LqdTypography>
        <Box margin="28px">
          <BuilderSimulatorHighlightCards inputSearchData={inputSearchData} simulation={builderSimulation!} />
        </Box>
      </Stack>
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Tabelas
        </LqdTypography>
        <Box margin="28px">
          <BuilderSimulatorTables inputSearchData={inputSearchData} simulation={builderSimulation!} type="tester" />
        </Box>
      </Stack>
    </Box>
  );
}
