import { LqdFunctionIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { SimulatorTesterCustomNodeData } from "@simulatorBuilder/types/simulationTester";

type EmptyNodeProps = {
  data: SimulatorTesterCustomNodeData;
};

export default function EmptyNode(props: EmptyNodeProps) {
  const { data } = props;
  return (
    <Stack
      sx={{
        background: "rgba(255, 255, 255, 1)",
        border: data.unusedBlocks.includes(data.block.block_id)
          ? "1px dashed rgba(255, 0, 0, 1)"
          : "1px solid rgba(207, 211, 218, 1)",
        borderRadius: "20px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        fontFamily: "Arial, sans-serif",
        minWidth: "287px",
        padding: "15px",
        paddingLeft: 2.5,
        width: "fit-content",
      }}
    >
      <Stack alignItems="center" sx={{ flexDirection: "row", marginBottom: "10px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "rgba(212, 215, 220, 1)",
            borderRadius: "50%",
            color: "rgba(13, 65, 122, 1)",
            height: 38,
            width: 38,
          }}
        >
          <LqdFunctionIcon size={18} />
        </Stack>

        <Stack sx={{ marginLeft: "10px" }}>
          <LqdTypography color="rgba(0, 0, 0, 1)" textstyle="h5Headline">
            {data.block.name}
          </LqdTypography>
          <LqdTypography color="rgba(101, 110, 127, 1)" textstyle="c1Caption">
            {data.block.block_id}
          </LqdTypography>
        </Stack>
      </Stack>
      <Box
        sx={{
          alignItems: "center",
          background: "rgba(249, 249, 250, 1)",
          borderRadius: 1.5,
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
          mb: 2,
          mt: 1.5,
          padding: 1.5,
          textAlign: "center",
          width: "60%",
        }}
      >
        <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="l2Label">
          Ops. Parece que você ainda não configurou os blocos.
        </LqdTypography>
      </Box>
    </Stack>
  );
}
