import { Simulator } from "@simulatorBuilder/types/Simulator";

export default function setUnusedBlocks(simulator: Simulator): Array<number> {
  const unusedBlocks = new Set(simulator.blocks.map((block) => block.block_id));
  const visitedBlocks = new Set<number>();

  function markBlockAsUsed(blockId: number | string) {
    unusedBlocks.delete(Number(blockId));
    visitedBlocks.add(Number(blockId));
  }

  let currentIndex = 0;

  while (currentIndex < simulator.blocks.length) {
    const currentBlock = simulator.blocks[currentIndex];

    const { block_id } = currentBlock;

    markBlockAsUsed(block_id);

    const jumpFunction = currentBlock.functions.find((func) => func.function_name === "jump");
    if (jumpFunction) {
      const targetBlockId = jumpFunction.variables.block_id;
      markBlockAsUsed(targetBlockId);

      // Verifique se o índice encontrado é válido
      const targetIndex = simulator.blocks.findIndex((block) => block.block_id === Number(targetBlockId));

      if (targetIndex !== -1) {
        currentIndex = targetIndex;
        continue;
      }
    }

    const jumpConditionalFunction = currentBlock.functions.find((func) => func.function_name === "jump_conditional");
    if (jumpConditionalFunction) {
      const booleanVar = jumpConditionalFunction.variables.a;
      const booleanValue = simulator.variables.find((variable) => variable.name === booleanVar)?.value;

      const blockTrue = Number(jumpConditionalFunction.variables.block_true);
      const blockFalse = Number(jumpConditionalFunction.variables.block_false);

      if (booleanValue === '"true"' && !visitedBlocks.has(Number(blockTrue))) {
        markBlockAsUsed(blockTrue);
        currentIndex = simulator.blocks.findIndex((block) => block.block_id === blockTrue);
      } else if (booleanValue === '"false"') {
        markBlockAsUsed(blockFalse);
        currentIndex = simulator.blocks.findIndex((block) => block.block_id === blockFalse);
      } else {
        currentIndex += 1;
      }
      continue;
    }
    currentIndex += 1;
  }

  return Array.from(unusedBlocks);
}
