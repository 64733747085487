import { LqdIconProps } from "../../types/IconProps";

export default function LqdConstantVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 15 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 15 15" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M12.5944 8.29386L8.57783 12.3104C8.1893 12.699 7.5593 12.699 7.17077 12.3104L3.15419 8.29386C2.76566 7.90533 2.76566 7.27533 3.15419 6.88681L7.17077 2.87022C7.5593 2.48169 8.1893 2.48169 8.57783 2.87022L12.5944 6.88681C12.9829 7.27587 12.9829 7.90533 12.5944 8.29386Z"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
}
