import {
  LqdButton,
  LqdCircularLoader,
  LqdExternalLinkIcon,
  LqdPopUp,
  LqdPopUpActions,
  LqdTermsIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import EULAOptionOneChapterZero from "../../signin/components/EULA/TermsAndConditionsOptionsOne/00Chapter";
import EULAOptionOneChapterOne from "../../signin/components/EULA/TermsAndConditionsOptionsOne/01Chapter";
import EULAOptionOneChapterTwo from "../../signin/components/EULA/TermsAndConditionsOptionsOne/02Chapter";
import EULAOptionOneChapterThree from "../../signin/components/EULA/TermsAndConditionsOptionsOne/03Chapter";
import EULAOptionOneChapterFour from "../../signin/components/EULA/TermsAndConditionsOptionsOne/04Chapter";
import EULAOptionOneChapterFive from "../../signin/components/EULA/TermsAndConditionsOptionsOne/05Chapter";
import EULAOptionOneChapterSix from "../../signin/components/EULA/TermsAndConditionsOptionsOne/06Chapter";
import EULAOptionOneChapterSeven from "../../signin/components/EULA/TermsAndConditionsOptionsOne/07Chapter";
import EULAOptionOneChapterEight from "../../signin/components/EULA/TermsAndConditionsOptionsOne/08Chapter";
import EULAOptionOneChapterNine from "../../signin/components/EULA/TermsAndConditionsOptionsOne/09Chapter";
import EULAOptionOneChapterTen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/10Chapter";
import EULAOptionOneChapterEleven from "../../signin/components/EULA/TermsAndConditionsOptionsOne/11Chapter";
import EULAOptionOneChapterTwelve from "../../signin/components/EULA/TermsAndConditionsOptionsOne/12Chapter";
import EULAOptionOneChapterThirteen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/13Chapter";
import EULAOptionOneChapterFourteen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/14Chapter";
import EULAOptionOneChapterFifteen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/15Chapter";
import EULAOptionOneChapterSixteen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/16Chapter";
import EULAOptionOneChapterSeventeen from "../../signin/components/EULA/TermsAndConditionsOptionsOne/17Chapter";
import SignInRequest from "../../signin/types/SignInRequest";
import { dialogCalled, dialogLoaded, termsCalled, toastCalled } from "../commonSlice";
import { useScrollFadeEffect } from "../hooks/useScrollFadeEffect";
import useWindowSize from "../hooks/useWindowSize";
import { ToastNotification } from "../types/ToastNotification";
import { useErrorHandler } from "../utils/useErrorHandler";
import { SmallDialogAction } from "./SmallDialog/SmallDialog";

type PopUpProps = {
  signInMethod: (signInForm: SignInRequest) => Promise<void>;
};

export default function PopUp(props: PopUpProps) {
  const { signInMethod } = props;

  const { width } = useWindowSize();

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();
  const scrollBoxRef = useScrollFadeEffect();

  const { dialogLoading, showTermsAndConditionsPopUp, termsAndConditionLoginForm } = useAppSelector(
    (state) => state.common
  );

  const onAcceptAgreementPopUp = async () => {
    dispatch(dialogLoaded(true));

    const form: SignInRequest = {
      agreement_term: { acceptance: true, code: "compliance_v2" },
      password: termsAndConditionLoginForm!.password,
      username: termsAndConditionLoginForm!.username,
    };

    try {
      await signInMethod(form);
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(termsCalled(false));
    }
  };

  const onRefuseAgreementToast = async () => {
    dispatch(dialogCalled(null));

    const notification: ToastNotification = {
      message: "É necessário aceitar os Termos e Condições de Uso para utilizar a plataforma Liquid.",
      type: "error",
    };

    dispatch(toastCalled(notification));
  };

  const onAcceptAgreementToast = () => {
    dispatch(dialogCalled(null));
    dispatch(termsCalled(true));
  };

  const onRefuseAgreementPopUp = () => {
    dispatch(
      dialogCalled({
        actions: [
          { onClick: onRefuseAgreementToast, title: "Não" },
          { onClick: onAcceptAgreementToast, title: "Sim, voltar" },
        ],
        body: `Ao não aceitar os Termos e Condições de Uso, você não poderá utilizar a plataforma.
     Deseja voltar para dar o aceite?`,
        title: "Aviso Importante",
        type: "default",
      })
    );

    dispatch(termsCalled(false));
  };

  // TODO: remover o popUpContent desse componente e passar para fora, chamando no state possivelmente (igual o Dialog).
  const popUpContent = {
    actions: [
      {
        onClick: onRefuseAgreementPopUp,
        title: "Eu não aceito os Termos",
      },
      {
        onClick: onAcceptAgreementPopUp,
        title: "Eu li e aceito os Termos e Condições de Uso",
      },
    ],
    body: (
      <>
        <Stack maxHeight="220px" overflow="auto" pr={3} ref={scrollBoxRef} textAlign="justify">
          <Stack gap={3}>
            <EULAOptionOneChapterZero />
            <EULAOptionOneChapterOne />
            <EULAOptionOneChapterTwo />
            <EULAOptionOneChapterThree />
            <EULAOptionOneChapterFour />
            <EULAOptionOneChapterFive />
            <EULAOptionOneChapterSix />
            <EULAOptionOneChapterSeven />
            <EULAOptionOneChapterEight />
            <EULAOptionOneChapterNine />
            <EULAOptionOneChapterTen />
            <EULAOptionOneChapterEleven />
            <EULAOptionOneChapterTwelve />
            <EULAOptionOneChapterThirteen />
            <EULAOptionOneChapterFourteen />
            <EULAOptionOneChapterFifteen />
            <EULAOptionOneChapterSixteen />
            <EULAOptionOneChapterSeventeen />
          </Stack>
        </Stack>
        <Box
          sx={{
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
            bottom: 0,
            height: "55px", // é essa propriedade que define o tamanho do "fade" no final do texto
            left: 0,
            pointerEvents: "none",
            position: "absolute",
            right: 0,
          }}
        />
      </>
    ),
    subtitle: `Antes de continuar, por favor, confirme que você leu e aceita nossos Termos e Condições de Uso.
    Ao clicar em "Aceito", você concorda em cumprir as condições estabelecidas.`,
    title: (
      <Stack display="flex" flexDirection="row">
        <LqdTypography color="rgba(23, 23, 23, 1)" textstyle="h5Headline">
          Termos e Condições de Uso do
        </LqdTypography>
        <LqdTypography color="rgba(23, 23, 23, 1)" sx={{ fontStyle: "italic", mx: 0.75 }} textstyle="h5Headline">
          Software
        </LqdTypography>
        <LqdTypography color="rgba(23, 23, 23, 1)" textstyle="h5Headline">
          Liquid
        </LqdTypography>
      </Stack>
    ),
  };

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "ghostPrimary";

  return (
    <LqdPopUp onClose={() => dispatch(termsCalled(false))} open={showTermsAndConditionsPopUp}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "rgba(240, 241, 243, 1)", borderRadius: "50%", height: "48px", mb: 2, width: "48px" }}
      >
        <LqdTermsIcon color="rgba(127, 135, 152, 1)" />
      </Stack>
      <>
        {popUpContent.title}
        <Divider sx={{ backgroundColor: "rgba(155, 162, 175, 1)", my: 2 }} />
        <LqdTypography
          color="rgba(23, 23, 23, 1)"
          sx={{ mb: 1.75, textAlign: "justify" }}
          textstyle={width === "xs" ? "c1Caption" : "p2Paragraph"}
        >
          {popUpContent.subtitle}
        </LqdTypography>
        <Box
          position="relative"
          sx={{ backgroundColor: "rgba(179, 169, 255, 0.04)", borderRadius: "12px", mb: 1.75, p: 2.5 }}
        >
          {popUpContent.body}
        </Box>
        <Box sx={{ alignItems: "center", color: "rgba(51, 128, 255, 1)", display: "flex", mt: 2.25 }}>
          <LqdTypography
            color="rgba(51, 128, 255, 1)"
            onClick={() => navigate("/terms-and-conditions")}
            sx={{ cursor: "pointer", maxWidth: { sm: "100%", xs: "137px" } }}
            textstyle={width === "xs" ? "c1Caption" : "p2Paragraph"}
          >
            Termos e Condições de Uso e Política de Privacidade
          </LqdTypography>
          <Box sx={{ height: 20, ml: 1, width: 16 }}>
            <LqdExternalLinkIcon size={16} />
          </Box>
        </Box>
        <LqdPopUpActions>
          {popUpContent.actions.map((action, index, actions) => {
            return (
              <LqdButton
                buttonsize={width === "xs" ? "small" : "large"}
                disabled={dialogLoading}
                key={action.title}
                onClick={action.onClick}
                type={getButtonType(index, actions)}
              >
                {dialogLoading && actions.length > 1 && index !== 0 ? (
                  <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
                ) : (
                  action.title
                )}
              </LqdButton>
            );
          })}
        </LqdPopUpActions>
      </>
    </LqdPopUp>
  );
}
