// TODO: Criar uma função "única" (não necessariamente), mas que comporte diversos tipos de formato de data e retorne em um objeto
// para que não sejam necessárias diversas funções fazendo a "mesma coisa", só retornando o dado em um formato levemente diferente.

import { Timestamp } from "../../common/types/Timestamp";

const MINIMUM_TIMESTAMP = -3786825600; // Timestamp referente ao dia 01/01/1850.

export const getDateFormatWithHours = (timestamp: Timestamp) => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(Number(timestamp) * 1000);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getDateFormatWithHoursAndSeconds = (timestamp: Timestamp) => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(Number(timestamp) * 1000);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const monthNames = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

  return `${day} ${month} ${year} - ${hours}h${minutes}m${seconds}s`;
};

export const getDateFormat = (timestamp: Timestamp) => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(Number(timestamp) * 1000);
  const getDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const getMonth = date.getMonth() + 1;

  if (getMonth < 10) {
    return `${getDay}/0${getMonth}/${date.getFullYear()}`;
  }

  return `${getDay}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getHourFormat = (timestamp: Timestamp) => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(timestamp * 1000);
  const getHour = date.getHours();
  const getMinutes = date.getMinutes();

  let hour = getHour.toString();
  let minutes = getMinutes.toString();

  if (getHour < 10) {
    hour = `0${getHour}`;
  }

  if (getMinutes < 10) {
    minutes = `0${getMinutes}`;
  }

  return `${hour}:${minutes}`;
};

export const getAccountFormat = (account: string) => {
  const bankAccount = account.split("/");
  return `${bankAccount[1]} / ${bankAccount[0].slice(0, bankAccount[0].length - 3)}xx-${bankAccount[0].slice(
    bankAccount[0].length - 1
  )}`;
};

export const getPortugueseFullDate = (timestamp: Timestamp): string => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const getDate = new Date(timestamp * 1000).toLocaleDateString("pt-BR", {
    day: "numeric",
    month: "long",
    timeZone: "UTC",
    year: "numeric",
  });

  return getDate;
};

export const formatHash = (rawHash: string): string => {
  if (!rawHash) return "Informação não encontrada";

  const hash = rawHash.split("");
  const formattedHash = `${hash[0]}${hash[1]}${hash[2]}${hash[3]}${hash[4]}${hash[6]}
  ...${hash[hash.length - 3]}${hash[hash.length - 2]}${hash[hash.length - 1]}`;

  return formattedHash;
};

export const formatDocument = (document: string) => {
  if (!document) return "";

  if (document.length === 11) {
    const doc = document.split("");
    const firstPart = doc.slice(0, 3).join("");
    const secondPart = doc.slice(3, 6).join("");
    const thirdPart = doc.slice(6, 9).join("");
    const lastPart = doc.slice(9, 11).join("");
    const maskedDoc = `${firstPart}.${secondPart}.${thirdPart}-${lastPart}`;
    return maskedDoc;
  }

  if (document.length === 14) {
    const doc = document.split("");
    const firstPart = doc.slice(0, 2).join("");
    const secondPart = doc.slice(2, 5).join("");
    const thirdPart = doc.slice(5, 8).join("");
    const fourthPart = doc.slice(8, 12).join("");
    const lastPart = doc.slice(12, 14).join("");
    const maskedDoc = `${firstPart}.${secondPart}.${thirdPart}/${fourthPart}-${lastPart}`;
    return maskedDoc;
  }

  return document;
};

export const formatValueToCurrency = (value: number, currency: string, showCurrency: boolean) => {
  if (value === null || value === undefined) return "";

  const getCurrency = new Intl.NumberFormat("pt-BR", {
    currency,
    style: "currency",
  }).format(value);

  const result = getCurrency.split("");

  const filterResult = result
    .filter((item, index) => {
      if (index > 2) return item;
    })
    .join("");

  if (showCurrency) {
    return getCurrency;
  }

  return filterResult;
};

export const formatValueToPercentage = (value: number) => {
  if (value === null || value === undefined) return "";

  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "percent",
  }).format(value);
};

export const formatValueToAddZeroes = (value: number, format: "CEP" | "CNPJ" | "CPF") => {
  const formats = {
    CEP: { length: 8, pattern: /^(\d{5})(\d{3})$/, replacement: "$1-$2" },
    CNPJ: { length: 14, pattern: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, replacement: "$1.$2.$3/$4-$5" },
    CPF: { length: 11, pattern: /^(\d{3})(\d{3})(\d{3})(\d{2})$/, replacement: "$1.$2.$3-$4" },
  };

  if (!value || !formats[format]) return "";

  const valueToString = value.toString().padStart(formats[format].length, "0");
  const valueAfterFormat = valueToString.replace(formats[format].pattern, formats[format].replacement);

  return valueAfterFormat;
};

export const formatBirthDate = (date: string) => {
  if (!date) return "";

  const splittedDate = date.split("-");
  const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;

  return formattedDate;
};

export const formatTimestampToHistoryDate = (timestamp: Timestamp) => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(timestamp * 1000);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear() % 100;

  const formattedTime = `${hours}h${minutes < 10 ? "0" : ""}${minutes}`;
  const formattedDate = `${day} ${month} ${year}`;

  return `${formattedTime} - ${formattedDate}`;
};

export const formatProductCreationDate = (timestamp: Timestamp, language: "pt-BR" | "en-US"): string => {
  if (timestamp === null || timestamp === undefined || timestamp < MINIMUM_TIMESTAMP) return "";

  const date = new Date(timestamp);
  const months =
    language === "pt-BR"
      ? ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
      : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours() % 12 || 12;
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day} ${month} ${hours}:${minutes} ${ampm}`;
};

export const formatDatabaseDate = (date: string, format: string): string => {
  if (!date) return "";

  const updatedDate = date.slice(8, 10) + format + date.slice(5, 7) + format + date.slice(0, 4);
  return updatedDate;
};

export const parseString = (string: string) => string.replace(/\.|-|\//g, "");

const parseDate = (dateString: string) => {
  if (!dateString) return null;

  const dateParts = dateString.split("/");
  const unexpectedDateLengths = dateParts.length !== 3 || dateString.length !== 10;
  if (unexpectedDateLengths) return null;

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) return null;

  const date = new Date(year, month, day);
  if (date.getDate() !== day || date.getMonth() !== month || date.getFullYear() !== year) return null;

  return date;
};

export const isValidDate = (dateString: string) => {
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!datePattern.test(dateString)) {
    return false;
  }

  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

export const isValidEndDate = (startDateString: string, endDateString: string) => {
  const startDate = parseDate(startDateString);
  const endDate = parseDate(endDateString);
  const today = new Date();

  if (!startDate || !endDate) return false;
  if (startDate > endDate || endDate > today) return false;

  return true;
};

const formatDateToDDMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatCardValueBasedOnType = (value: number | string, type: string, label: string) => {
  if (type === "textDefault") return label;
  if (!value) return "Informação não encontrada";

  switch (type) {
    case "boolean":
      return value.toString().toLowerCase() === "true" ? "Sim" : "Não";
    case "currency":
      return formatValueToCurrency(Number(value), "BRL", true);
    case "date":
      if (typeof value === "string") return formatDateToDDMMYYYY(value);
      else return getDateFormat(value);
    case "decimal":
    case "percentage":
      return formatValueToPercentage(Number(value));
    case "integer":
      return Number(value).toString();
    default:
      return value.toString();
  }
};

export const formatPhone = (phone: string) => {
  if (!phone) return "";

  return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 $2 $3-$4");
};
