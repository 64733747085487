import { LqdFunctionIcon, LqdTypography } from "@/liquid-components/src";
import LqdConstantVariableIcon from "@/liquid-components/src/icons/ConstantVariable/ConstantVariable";
import LqdInputVariableIcon from "@/liquid-components/src/icons/InputVariable/InputVariable";
import LqdResultVariableIcon from "@/liquid-components/src/icons/ResultVariable/ResultVariable";
import { highlightText } from "@common/utils/highlightText";
import { normalizeText } from "@common/utils/normalizeText";
import { Stack } from "@mui/material";
import { SimulatorTesterCustomNodeData } from "@simulatorBuilder/types/simulationTester";
import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunction } from "@simulatorBuilder/types/SimulatorFunction";

type SimulatorTesterCustomNodeFunctionsTemplateProps = {
  data: SimulatorTesterCustomNodeData;
  func: SimulatorFunction;
  searchedText: string;
  simulator: Simulator;
  testerResult: SimulatorTesterResultDBResponse | null;
};

export default function SimulatorTesterCustomNodeFunctionsTemplate(
  props: SimulatorTesterCustomNodeFunctionsTemplateProps
) {
  const { func, searchedText, simulator } = props;

  const renderIcon = (category: string, displayValue: string) => {
    switch (category) {
      case "input":
        return (
          <LqdInputVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );

      case "constant":
        return (
          <LqdConstantVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );
      case "result":
        return (
          <LqdResultVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );
      default:
        return;
    }
  };

  return (
    <Stack>
      {Object.entries(func.variables).map(([varKey, varValue], i) => {
        let displayValue =
          simulator.variables.filter((v) => v.category !== "default").find((v) => v.name === varValue)?.label || "";

        let variableCategory = simulator.variables.find((v) => v.name === varValue)?.category || "";

        if (func.function_name === "jump" && varKey === "block_id") {
          variableCategory = simulator.blocks.find((b) => b.block_id === varValue)?.name || "";

          const foundBlock = simulator.blocks.find((bl) => bl.block_id === Number(varValue));

          if (foundBlock) {
            displayValue = foundBlock.name;
          }
        } else if (func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false")) {
          variableCategory = simulator.blocks.find((b) => b.block_id === varValue)?.name || "";

          const foundVariable = simulator.blocks.find((b) => b.block_id === Number(varValue));
          if (foundVariable) {
            displayValue = foundVariable.name;
          }
        }
        return (
          <Stack
            key={i}
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: 1.25,
              marginBottom: 1,
            }}
          >
            {func.function_name === "jump" && varKey === "block_id" ? (
              <Stack alignItems="center" flexDirection="row" gap={1.25}>
                <LqdFunctionIcon
                  color={
                    searchedText && normalizeText(displayValue).includes(searchedText)
                      ? "rgba(127, 135, 152, 1)"
                      : "rgba(207, 211, 218, 1)"
                  }
                  size={18}
                />
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                  {highlightText(displayValue.toString(), searchedText, "12px")}
                </LqdTypography>
              </Stack>
            ) : null}
            {func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false") ? (
              <Stack alignItems="center" flexDirection="row" gap={1.25}>
                <LqdFunctionIcon
                  color={
                    searchedText && normalizeText(displayValue).includes(searchedText)
                      ? "rgba(127, 135, 152, 1)"
                      : "rgba(207, 211, 218, 1)"
                  }
                  size={18}
                />
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                  {highlightText(displayValue.toString(), searchedText, "12px")}
                </LqdTypography>
              </Stack>
            ) : null}
            {!(func.function_name === "jump" && varKey === "block_id") &&
            !(func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false")) ? (
              <Stack alignItems="center" flexDirection="row" gap={1.25} width="100%">
                {renderIcon(variableCategory, displayValue)}
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                  {highlightText(displayValue.toString(), searchedText, "12px")}
                </LqdTypography>
              </Stack>
            ) : null}
          </Stack>
        );
      })}
      {func.result.r ? (
        <Stack
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            gap: 1.25,
            marginBottom: 1.5,
          }}
        >
          <LqdResultVariableIcon
            color={
              searchedText && func.result.r.includes(searchedText) ? "rgba(127, 135, 152, 1)" : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
            {highlightText(func.result.r, searchedText, "12px")}
          </LqdTypography>
        </Stack>
      ) : null}
    </Stack>
  );
}
