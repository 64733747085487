import {
  LqdBreadcrumb,
  LqdButton,
  LqdCircularLoader,
  LqdDownArrowDashedIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Box, Stack } from "@mui/material";
import { hasVariableAlterations } from "@simulatorBuilder/utils/simulatorAlterations";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import FullscreenDialog from "../../../../common/components/FullscreenDialog";
import { useErrorHandler } from "../../../../common/utils/useErrorHandler";
import {
  onUpdateSimulator,
  simulatorBuilderChanged,
  simulatorBuilderHasAlterations,
} from "../../../simulatorBuilderSlice";
import { Simulator } from "../../../types/Simulator";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";
import { ModalStepType, SimulatorVariable } from "../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../types/SimulatorVariableTypeTemplate";
import SimulatorVariableCardForm from "./SimulatorVariableCardForm";
import SimulatorVariableEditForm from "./SimulatorVariableEditForm";

type SimulatorVariablesFormProps = {
  blocks: Array<SimulatorFunctionBlock>;
  onClose: (saving: boolean) => void;
};

/** Tela de setup de variáveis do builder de simulador. */
export default function SimulatorVariablesForm(props: SimulatorVariablesFormProps) {
  const { blocks, onClose } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [loading, setLoading] = useState<boolean>(false);
  const [openEditVariableNameModal, setOpenEditVariableNameModal] = useState(false);
  const [modalStep, setModalStep] = useState<ModalStepType>("");
  const [variableBeingEdited, setVariableBeingEdited] = useState<SimulatorVariable | null>(null);
  const [variableBeingEditedIndex, setVariableBeingEditedIndex] = useState<number | null>(null);

  const emptyNewVariable = {
    category: "input",
    id: uuid(),
    is_input: true,
    label: "",
    name: "",
    order_index: 0,
    solver: null,
    type: null,
    value: "",
  };

  /** Insere a chave order_index nas variáveis de simuladores antigos que não possuem essa chave
   * @param variables Lista de variáveis do simulador
   * @returns Lista de variáveis com a chave order_index populada
   */
  const populateOrderIndex = (variables: Array<SimulatorVariable>) => {
    let orderIndex = -1;

    const populatedVariables = variables.map((variable) => ({
      ...variable,
      order_index: variable.is_input ? ++orderIndex : null,
    }));

    dispatch(simulatorBuilderChanged({ key: "variables", value: [...populatedVariables] }));
    return populatedVariables;
  };

  const shouldPopulateOrderIndex = simulator.variables.some(
    (variable) => variable.order_index === null && variable.is_input
  );

  const simulatorVariables = shouldPopulateOrderIndex ? populateOrderIndex(simulator.variables) : simulator.variables;

  useEffect(() => {
    if (!simulatorVariables.some((variable) => variable.is_input)) {
      setTimeout(() => {
        dispatch(simulatorBuilderChanged({ key: "variables", value: [...simulatorVariables, emptyNewVariable] }));
      }, 0);
    }
  }, []);

  /**  Adiciona uma nova variável à lista de variáveis.*/
  const onAddVariableClick = () => {
    setVariableBeingEdited(emptyNewVariable);
    setModalStep("type");
    setOpenEditVariableNameModal(true);
    setVariableBeingEditedIndex(simulatorVariables.length);
  };

  /** Permite salvar se todas as variáveis tiverem com nome preenchido e tipo definido.*/
  const variableDuplicatesCount = () => {
    const labelCountMap = new Map();

    const inputVariables = simulatorVariables.filter((variable) => variable.is_input);

    for (const variable of inputVariables) {
      if (labelCountMap.has(variable.label)) {
        labelCountMap.set(variable.label, labelCountMap.get(variable.label) + 1);
      } else {
        labelCountMap.set(variable.label, 1);
      }
    }

    return Array.from(labelCountMap.values()).some((count) => count > 1);
  };

  const maySubmitSave =
    !simulatorVariables.some((variable) => !variable.label.length || variable.type === null) &&
    !variableDuplicatesCount() &&
    hasVariableAlterations(simulatorVariables);

  const mayAddNewVariable =
    !simulatorVariables.some((variable) => !variable.label.length || variable.type === null) &&
    !variableDuplicatesCount();

  /** Aplica a mudança de type em uma variável
   * @param variable Variável a ser alterada
   * @param variableIndex Índice da variável a ser alterada
   * @param type Novo tipo da variável
   */
  const onSimulatorVariableTypeChange = (
    variable: SimulatorVariable,
    variableIndex: number,
    type: SimulatorVariableTemplateType
  ) => {
    const updatedVariable = { ...variable!, type: type };

    const updatedSimulatorVariables = simulatorVariables.map((vari, index) =>
      index === variableIndex ? updatedVariable : vari
    );

    dispatch(simulatorBuilderChanged({ key: "variables", value: updatedSimulatorVariables }));
  };

  /** Duplica uma variável
   * @param variable Variável a ser duplicada
   */
  const onDuplicateVariableClick = (variable: SimulatorVariable) => {
    const lastVariable = simulatorVariables[simulatorVariables.length - 1];
    const duplicatedVariable = { ...variable, order_index: lastVariable.order_index! + 1 };

    const updatedSimulatorVariables = [...simulatorVariables, duplicatedVariable];
    dispatch(simulatorBuilderChanged({ key: "variables", value: updatedSimulatorVariables }));
  };

  /** Exclui uma variável
   * @param variableIndex Índice da variável a ser excluída
   */
  const onRemoveVariableClick = (variableIndex: number) => {
    const removedVariable = simulatorVariables[variableIndex];

    const updatedSimulatorVariables = [...simulatorVariables];
    updatedSimulatorVariables.splice(variableIndex, 1);

    const variableWhoTookRemovedVariablePlace = updatedSimulatorVariables[variableIndex];

    updatedSimulatorVariables[variableIndex] = {
      ...variableWhoTookRemovedVariablePlace,
      order_index: removedVariable.order_index,
    };

    const reorderedUpdatedSimulatorVariables = updatedSimulatorVariables.map((updatedVariable, index) => {
      const reorderedVariable = { ...updatedVariable, order_index: updatedVariable.order_index! - 1 };
      return index > variableIndex ? reorderedVariable : updatedVariable;
    });

    dispatch(simulatorBuilderChanged({ key: "variables", value: reorderedUpdatedSimulatorVariables }));
  };

  /** Faz o submit das variáveis ao backend.*/
  const updateSimulator = async () => {
    setLoading(true);

    // Valida se há alguma variável alterada (se existe a variável: edita; se não, faz um push)
    const updatedVariables = [...simulator.variables, ...simulatorVariables];

    for (const newVariable of simulatorVariables) {
      const index = updatedVariables.findIndex((oldVariable) => oldVariable.name === newVariable.name);

      if (index === -1) {
        updatedVariables.push(newVariable);
      } else {
        updatedVariables[index] = newVariable;
      }
    }

    const uniqueVariablesMap = new Map();
    for (const variable of updatedVariables) {
      uniqueVariablesMap.set(variable.name, variable);
    }

    const uniqueVariables = Array.from(uniqueVariablesMap.values());
    const updatedSimulator = { ...simulatorData, variables: uniqueVariables } as Simulator;

    try {
      const requestData = { blocks, simulator: updatedSimulator, simulatorId: updatedSimulator.simulator_id };
      await dispatch(onUpdateSimulator(requestData)).unwrap();
      onClose(true);
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      setLoading(false);
      dispatch(simulatorBuilderHasAlterations(false));
    }
  };

  return openEditVariableNameModal ? (
    <FullscreenDialog
      onClose={() => {
        setModalStep("");
        setOpenEditVariableNameModal(false);
      }}
      open={openEditVariableNameModal}
      title="Editar variável"
    >
      <SimulatorVariableEditForm
        modalStep={modalStep}
        setModalStep={setModalStep}
        setOpenEditVariableNameModal={setOpenEditVariableNameModal}
        setVariableBeingEdited={setVariableBeingEdited}
        variableBeingEdited={variableBeingEdited}
        variableBeingEditedIndex={variableBeingEditedIndex}
      />
    </FullscreenDialog>
  ) : (
    <>
      <Box sx={{ backgroundColor: "rgba(240, 241, 243, 1)", py: 1.5, width: "100%" }}>
        <Box sx={{ ml: "100px" }}>
          <LqdBreadcrumb
            options={[{ name: simulator.name, onClick: () => onClose(false) }, { name: "Variáveis de Entrada" }]}
            separator="/"
          />
        </Box>
      </Box>
      <Box sx={{ margin: "auto", maxWidth: "860px", pb: "80px", width: "100%" }}>
        <Stack spacing={2.5} sx={{ mt: "60px" }}>
          {simulatorVariables.map((variable, index) =>
            variable.is_input ? (
              <>
                <SimulatorVariableCardForm
                  key={`${variable.name}-${index}`}
                  onDuplicateClick={() => onDuplicateVariableClick(variable)}
                  onEditNameClick={() => {
                    setVariableBeingEdited(variable);
                    setOpenEditVariableNameModal(true);
                    setVariableBeingEditedIndex(index);
                    setModalStep("name");
                  }}
                  onRemoveClick={() => onRemoveVariableClick(index)}
                  onSimulatorVariableTypeChange={(value) => onSimulatorVariableTypeChange(variable, index, value)}
                  simulatorVariable={variable}
                />
                {index !== simulatorVariables.length - 1 ? (
                  <Stack alignItems="center">
                    <LqdDownArrowDashedIcon size={32} />
                  </Stack>
                ) : null}
              </>
            ) : null
          )}
        </Stack>
        {variableDuplicatesCount() ? (
          <LqdTypography color="red" textAlign="right" textstyle="c1Caption">
            Uma ou mais variáveis possuem o mesmo nome. Altere o nome das variáveis para prosseguir.
          </LqdTypography>
        ) : null}
        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2.5 }}>
          <LqdButton disabled={!mayAddNewVariable || loading} onClick={onAddVariableClick} type="outlineTertiary">
            Adicionar outra variável
          </LqdButton>
          <LqdButton disabled={!maySubmitSave || loading} onClick={updateSimulator}>
            {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Salvar"}
          </LqdButton>
        </Stack>
      </Box>
    </>
  );
}
