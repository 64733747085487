import { LqdIconButton, LqdLeftArrowIcon, LqdTypography, LqdUserIcon } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import {
  dialogCalled,
  dialogErrorMessageChanged,
  dialogLoaded,
  termsCalled,
  termsFormChanged,
} from "../../common/commonSlice";
import PopUp from "../../common/components/PopUp";
import useAuth from "../../common/hooks/useAuth";
import { ObjectOf } from "../../common/types/ObjectOf";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { validateEmail } from "../../common/utils/validateEmail";
import { adminListTenantsLogin } from "../../superadmin/api/ListTenantsLogin";
import { TenantResponseRaw } from "../../superadmin/types/TenantResponseRaw";
import signIn from "../api/SignIn";
import { adminSignIn } from "../api/adminSignIn";
import { MFASignForm } from "../types/MFASignForm";
import SignInRequest from "../types/SignInRequest";
import { encrypt } from "../utils/encryptLogin";
import RenderSignInFormSteps from "./RenderSignInFormSteps";
import TenantSelectionModal from "./TenantSelectionModal";

type SignInFormProps = {
  sessionExpired?: boolean;
};

export default function SignInForm(props: SignInFormProps) {
  const { sessionExpired } = props;

  const { t } = useTranslation();

  const auth = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleLiquidErrors = useErrorHandler();

  const [currentStep, setCurrentStep] = useState(1);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [MFAForm, setMFAForm] = useState<MFASignForm | null>(null);
  const [password, setPassword] = useState("");
  const [selectedTenantCode, setSelectedTenantCode] = useState<string>("");
  const [showAdminTenantSelectionModal, setShowAdminTenantSelectionModal] = useState(false);
  const [signForm, setSignForm] = useState<{ password: string; username: string } | null>(null);
  const [signInError, setSignInError] = useState("");
  const [tenantList, setTenantList] = useState<Array<TenantResponseRaw>>([]);
  const [username, setUsername] = useState("");

  const encryptedPassword = encrypt(password);
  const signInForm: SignInRequest = { password: encryptedPassword, username };

  useEffect(() => {
    setEmailError(false);
  }, [username]);

  const handleMFAModalClick = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    dispatch(dialogCalled(null));

    dispatch(
      dialogCalled({
        actions: [
          { title: "Cancelar" },
          {
            onClick: (qrCode) => handleSubmit(event, { mfa: qrCode! as string, password, username }),
            title: "Verificar",
          },
        ],
        body: "Insira o código de autenticação.",
        bodySecundary: `Abra seu app de autenticação de dois fatores (TOTP) ou extensão no browser
        para verificar seu código de autenticação.`,
        title: "Autenticação de dois fatores",
        type: "confirmation",
      })
    );
  };

  const submitLogin = async (signInForm: { password: string; username: string }, MFASignInForm: MFASignForm | null) => {
    setLoading(true);

    try {
      let signInRes;

      const termsForm: SignInRequest = {
        password: signInForm.password,
        username: signInForm.username,
      };

      dispatch(termsFormChanged(termsForm));

      if (location.pathname === "/signin") {
        if (!MFASignInForm) setLoadingLogin(true);
        dispatch(dialogLoaded(true));
        signInRes = MFASignInForm ? await signIn(MFASignInForm) : await signIn(signInForm);
      } else {
        signInRes = MFASignInForm
          ? await adminSignIn(MFASignInForm, selectedTenantCode)
          : await adminSignIn(signInForm, selectedTenantCode);
      }

      const { access_token, mfa, mfa_qrcode_create, qrcode_url, refresh_token } = signInRes.data;

      if (mfa_qrcode_create) {
        return dispatch(
          dialogCalled({
            actions: [
              {
                onClick: () => handleMFAModalClick(event as unknown as FormEvent<HTMLFormElement>),
                title: "Verificar",
              },
            ],
            body: `Use seu aplicativo de autenticação para escanear este QR code. Se você não possui
            um aplicativo de autenticação, você deverá instalar um agora.`,
            bodySecundary: `No aplicativo, aponte a câmera para a tela. Ao finalizar o processo de
            escanear o QR Code, pressione avançar para seguir.`,
            qrCodeURL: qrcode_url!,
            title: "Vincule seu aplicativo à sua conta Liquid",
            type: "mfa",
          })
        );
      } else if (mfa) {
        return handleMFAModalClick(event as unknown as FormEvent<HTMLFormElement>);
      }

      auth.signIn(access_token, refresh_token);
      window.location.reload();
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const ERROR_HANDLER: ObjectOf<() => void> = {
        "invalid mfa code": () => dispatch(dialogErrorMessageChanged("Código inválido. Tente novamente.")),
        "invalid username/password": () => setSignInError("E-mail ou senha inválidos. Tente novamente."),
        "missing terms and conditions acceptance": () => dispatch(termsCalled(true)),
      };
      ERROR_HANDLER[message]();

      auth.signOut(false);
    } finally {
      setLoading(false);
      setLoadingLogin(false);
      dispatch(dialogLoaded(false));
    }
  };

  const showTenantSelecionList = async () => {
    setLoadingLogin(true);

    try {
      const tenantsRaw = await adminListTenantsLogin(username, password);
      const tenants = tenantsRaw.data;
      setTenantList(tenants);
      setShowAdminTenantSelectionModal(true);
    } catch (error) {
      handleLiquidErrors(error, t("Seu login é inválido neste ambiente. Contate os administradores."));
    } finally {
      setLoadingLogin(false);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>, MFAForm?: MFASignForm) => {
    if ("type" in event && event.type === "submit") event.preventDefault();

    let MFASignInForm: MFASignForm | null = null;
    if (MFAForm && Object.keys(MFAForm).length > 0) MFASignInForm = MFAForm;

    switch (currentStep) {
      case 1: {
        const emailIsValid = validateEmail(username);
        if (emailIsValid) setCurrentStep(2);
        return setEmailError(!emailIsValid);
      }
      case 2: {
        if (MFASignInForm) {
          MFASignInForm.password = encryptedPassword;
        }
        setSignForm(signInForm);
        setMFAForm(MFASignInForm);

        return location.pathname === "/signinadmin" && !MFASignInForm
          ? showTenantSelecionList()
          : submitLogin(signInForm, MFASignInForm);
      }
    }
  };

  const handleGoBack = () => {
    setPassword("");
    setSignInError("");
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {currentStep > 1 ? (
        <LqdIconButton
          buttonsize="large"
          onClick={handleGoBack}
          round="true"
          sx={{ ml: 3, mt: 3, position: "absolute" }}
          type="outlinePrimary"
        >
          <LqdLeftArrowIcon />
        </LqdIconButton>
      ) : null}
      <Box
        id="begin"
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          mx: { sm: 0, xs: 3 },
          px: { lg: "20%", md: "10%", sm: 6, xs: 3 },
          py: 4,
        }}
      >
        {location.pathname === "/signinadmin" ? (
          <Box
            sx={{
              alignItems: "center",
              border: "1px solid rgba(33, 36, 42, 1)",
              borderRadius: 6,
              display: "flex",
              mb: 2,
              p: 1.5,
              width: "fit-content",
            }}
          >
            <LqdUserIcon color="rgba(33, 36, 42, 1)" />
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ ml: 1 }} textstyle="buttonS">
              {t("Acesso de Administrador")}
            </LqdTypography>
          </Box>
        ) : null}
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1 }} textstyle="h3Headline">
          {t("Que bom te ver por aqui!")}
        </LqdTypography>
        {sessionExpired ? (
          <LqdTypography color="rgba(255, 181, 71, 1)" sx={{ mb: 8 }} textstyle="p2Paragraph">
            {t("Sua sessão expirou. Faça login novamente para entrar na plataforma.")}
          </LqdTypography>
        ) : (
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 8 }} textstyle="p2Paragraph">
            {t("Insira seus dados pessoais para entrar na plataforma.")}
          </LqdTypography>
        )}
        <RenderSignInFormSteps
          currentStep={currentStep}
          emailError={emailError}
          handleSubmit={handleSubmit}
          loading={loadingLogin}
          password={password}
          setPassword={setPassword}
          setSignInError={setSignInError}
          setUsername={setUsername}
          signInError={signInError}
          username={username}
        />
      </Box>
      <TenantSelectionModal
        isLoading={loading}
        onCloseMethod={() => setShowAdminTenantSelectionModal(false)}
        onConfirmMethod={() => submitLogin(signForm!, MFAForm)}
        open={showAdminTenantSelectionModal}
        selectedTenantCode={selectedTenantCode}
        setSelectedTenantCode={setSelectedTenantCode}
        tenantList={tenantList}
      />
      <PopUp signInMethod={submitLogin as (signInForm: SignInRequest) => Promise<void>} />
    </>
  );
}
